export const InstagramPostData = `
              data {
                attributes {
                  username
                  profilePhoto {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  description
                  likesAndComments
                  profileUrl
                }
              }
`;