import { OverlayRef } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnInit,
} from "@angular/core";
import { YouTubePlayer } from "@angular/youtube-player";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { ButtonComponent } from "../../../core/button/button.component";
import { ProductOption } from "../../../models/components/products/productOption.model";
import { ProductCardSingle } from "../../../models/components/sections/productcardsingle.model";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";
import { InformationTabsComponent } from "../../elements/information-tabs/information-tabs.component";
import { TextAndIconComponent } from "../../elements/text-and-icon/text-and-icon.component";

export const PRODUCT_CARD_PRIMARY_REF = new InjectionToken<OverlayRef>(
  "PRODUCT_CARD_PRIMARY_REF"
);

@Component({
  standalone: true,
  selector: 'app-product-card-primary-modal',
  templateUrl: './product-card-primary-modal.component.html',
  styleUrls: ['./product-card-primary-modal.component.css'],
  imports: [
    CommonModule,
    ButtonComponent,
    TextAndIconComponent,
    MarkdownModule,
    YouTubePlayer,
    InformationTabsComponent,
    FontAwesomeModule,
    ImageUrlDirective,
  ],
})
export class ProductCardPrimaryModalComponent implements OnInit {
  @Input() productCardSingle: ProductCardSingle;
  readonly faTimes = faTimes;
  selectedOption: ProductOption;
  videoPlaying = false;
  videoWidth = 900;
  videoHeight = 0.45 * this.videoWidth;
  strapiUrl: string;

  constructor(
    @Inject(PRODUCT_CARD_PRIMARY_REF) public overlayRef: OverlayRef,
    private readonly configService: AppConfigService
  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  ngOnInit(): void {
    this.selectedOption =
      this.productCardSingle.productCard.data.attributes.productOptions[0];
  }

  closePopup() {
    this.overlayRef.dispose();
  }

  startVideo() {
    this.videoPlaying = true;
  }
}
