import { Component, Input, OnInit } from '@angular/core';
import { ScrollingText } from '../../../models/components/sections/scrollingText.model';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports:[CommonModule],
  selector: 'app-scrolling-text',
  templateUrl: './scrolling-text.component.html',
  styleUrls: ['./scrolling-text.component.css'],
})
export class ScrollingTextComponent implements OnInit {
  @Input() scrollingText: ScrollingText;
  textArray: { normalText: string; outlineText: string }[] = [];

  ngOnInit(): void {
    for (let i = 0; i < 5; i++) {
      this.textArray.push({
        normalText: this.scrollingText.normalText,
        outlineText: this.scrollingText.outlineText,
      });
    }
  }
}
