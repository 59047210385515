import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { Badges } from "../../../models/components/sections/badges.model";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";

@Component({
  standalone: true,
  selector: "app-badges",
  templateUrl: "./badges.component.html",
  styleUrls: ["./badges.component.css"],
  imports: [CommonModule, MarkdownModule, ImageUrlDirective],
})
export class BadgesComponent {
  @Input() badges: Badges;
  strapiUrl: string;

  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }
}
