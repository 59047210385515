import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../../config.service';
import { FeedbackList } from '../../../models/collectionTypes/feedbackList.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';

@Component({
  standalone: true,
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.css'],
  imports: [CommonModule, ImageUrlDirective],
})
export class FeedbackListComponent {
  @Input() feedbackList: FeedbackList;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService
  ) {
    this.strapiUrl = this.configService.getConfigOrThrow().strapiUrl;
  }
}
