<div class="quote-wrapper">
  <div class="flex flex-col items-center gap-2">
    <div class="title">{{ quotes.title }}</div>
    <div *ngIf="currentQuote()" [@fade] class="quote">
      <img appImageUrl [iconData]="currentQuote()!.image.data" class="w-24" />
      <div class="flex flex-col items-center gap-2">
        <markdown class="quote-text" [data]="currentQuote()!.text" />
        <markdown class="quote-sign" [data]="currentQuote()!.username" />
      </div>

      <div class="move-button left" (click)="previousQuote()">
        <fa-icon
          [icon]="chevronLeftIcon"
          class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div class="move-button right" (click)="nextQuote()">
        <fa-icon
          [icon]="chevronRightIcon"
          class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
    </div>
    <div class="flex gap-2">
      @for (quote of quotes.quotes; track $index; let index = $index) {
        <div
          class="dot"
          [ngClass]="{ active: index === currentQuoteIndex() }"
        ></div>
      }
    </div>
  </div>

  <app-button [buttonLink]="quotes.button"></app-button>
</div>
