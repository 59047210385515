import { ButtonLinkData } from '../componentsData/buttonLink.componentData';

export const QuotesSection = `
          ... on ComponentSectionsQuotes {
            title
            button {
              ${ButtonLinkData}
            }
            quotes {
              text
              username
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
`;
