<div class="flex flex-col items-center w-full mt-14">
  <div class="wrapper ">
    <markdown class="text">{{ freeText.text }}</markdown>
    <div class="flex flex-col gap-2">
      <app-buttons-section
        [buttonsSection]="freeText.buttonsSection"
      ></app-buttons-section>
    </div>
  </div>
</div>
