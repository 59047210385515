import { gql } from "apollo-angular";

const UpdateProjectCountQuery = gql`
mutation ProjectLikeCounter($id: ID!, $counter: Long) {
  updateLikeCounter(id: $id, data: { counter: $counter }) {
    data {
      attributes {
        counter
      }
    }
  }
}
`;

export default UpdateProjectCountQuery;