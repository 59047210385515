<div class="absolute inset-0 z-50">
  <div
    class="fixed backdrop-brightness-50 h-full w-full flex place-items-center place-content-center"
    (click)="closePopup()"
  >
    <div class="modal" (click)="$event.stopPropagation()">
      <div class="header">
        <span class="relative z-10"> {{ fancybox.title }}</span>
        <div class="info-circle">
          <fa-icon [icon]="faLock"  />
        </div>
        <div class="close-button" (click)="closePopup()">
          <fa-icon [icon]="faTimes" />
        </div>
      </div>
      <markdown class="p-4">{{ fancybox.content }}</markdown>
    </div>
  </div>
</div>
