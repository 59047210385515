<div class="wrapper">
  <div class="flex flex-col items-center max-w-[80vw]">
    <div class="title">
      <h1 class="flex">Welcome,</h1>

      <div class="flex flex-row items-end gap-2">
        @if (user$ | async; as user) {
          <h1 class="text-red-600">
            {{ user.firstName }}
          </h1>
        }
      </div>
    </div>
    <span class="subtitle"
      >Your Santa Movie will be delivered straight to your email usually
      within minutes but it may take as long as an hour when
      our elves are processing a lot of orders.</span
    >
  </div>
  <div class="container">
    <div class="side-wrapper">
      <div class="side">
        <div class="title">
          <span class="step-number">1<span class="small">/2</span></span>
          <span>Your info</span>
        </div>
        <p>
          This will be used to process your purchase:
        </p>

        <div class="flex flex-col">
          <app-text-input
            [control]="formGroup.controls.firstName"
            placeholder="First name"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.controls.lastName"
            placeholder="Last Name"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.controls.email"
            placeholder="Email"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.controls.repeatEmail"
            placeholder="Repeat email"
          ></app-text-input>
          @if (
            formGroup.get("email")?.touched &&
            formGroup.get("repeatEmail")?.touched &&
            formGroup.errors?.["EmailsNoMatch"]
          ) {
            <span class="text-red-500 text-sm">Emails don't match</span>
          }
          <app-text-input
            [control]="formGroup.controls.street"
            placeholder="Street"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.controls.postalCode"
            placeholder="Postal code"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.controls.city"
            placeholder="City"
          ></app-text-input>
        </div>

        <div class="flex flex-col items-center gap-2">
          <span class="text-center">If you have a GiftCode or a Discount Code please apply it in the Cart by pressing button below.</span>
          <button (click)="returnToBasket()" class="back-button">
            Go back
          </button>
        </div>

        <div class="title">
          <span class="step-number">2<span class="small">/2</span></span>
          <span>Payment</span>
        </div>

        @if (!this.isOrderFree) {
          <div class="flex flex-col gap-2">
            <div class="flex flex-auto flex-col">
              <iframe
                class="w-full h-14"
                data-ifields-id="card-number"
                data-ifields-placeholder="Card number"
                [src]="cardKnoxCdnUrl"
              ></iframe>
              <iframe
                class="w-full h-14"
                data-ifields-id="cvv"
                data-ifields-placeholder="CVV"
                [src]="cardKnoxCdnUrl"
              ></iframe>
              <div class="flex gap-2 w-full">
                <app-text-input
                  class="flex-grow"
                  [control]="cardFormGroup.get('month')!"
                  placeholder="MM"
                ></app-text-input>
                <app-text-input
                  class="flex-grow"
                  [control]="cardFormGroup.get('year')!"
                  placeholder="YY"
                ></app-text-input>
              </div>
            </div>
            <div>
              <input name="xCVV" data-ifields-id="cvv-token" hidden />
              <input
                name="xCardNum"
                data-ifields-id="card-number-token"
                hidden
              />
            </div>
          </div>

          <button (click)="createOrder()" class="order-pay-button">
            Submit Order and Pay
          </button>
        } @else {
          <div class="flex flex-col gap-2">
            <p>Your order is free</p>
          </div>
          <button (click)="createOrder()" class="order-pay-button">
            Submit Order
          </button>
        }
      </div>
    </div>

    <div class="side-wrapper right">
      <div class="side">
        <div class="title !hidden md:!flex">
          <span>Your order</span>
        </div>
        <div class="summary" [ngClass]="{ closed: !isSummaryOpen() }">
          <div
            class="total small"
            (click)="isSummaryOpen.set(!isSummaryOpen())"
          >
            <div class="flex items-center gap-2">
              <span class="text-2xl font-semibold">Total</span>
              <fa-icon class="chevron-icon" [icon]="faChevronDown" />
            </div>
            <span class="font-bold text-2xl">{{ this.total | currency }}</span>
          </div>
          @if (this.orderRepayment) {
            @if (this.order$ | async; as order) {
              @for (item of order.orderItems; track $index) {
                <div class="flex gap-2">
                  <img
                    [src]="item.product.thumbnail"
                    class="h-28 object-contain rounded-md"
                  />
                  <div class="w-full flex justify-between">
                    <div class="flex flex-col items-start gap-2">
                      <div class="flex flex-col gap-1">
                        <div class="text-lg leading-4">
                          {{ item.product.type }}
                        </div>
                        <div class="text-gray-600">{{ item.product.name }}</div>
                      </div>
                    </div>
                    <div>
                      <div class="text-lg text-end">
                        {{ item.price | currency }}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              }
            }
          } @else {
            @for (item of basketItems; track $index) {
              <div class="flex gap-2">
                <img
                  [src]="item.thumbnailUrl"
                  class="h-28 object-contain rounded-md"
                />
                <div class="w-full flex justify-between">
                  <div class="flex flex-col items-start gap-2">
                    <div class="flex flex-col gap-1">
                      <div class="text-lg leading-4">
                        {{ productType[item.type] }}
                      </div>
                      <div class="text-gray-600">{{ item.name }}</div>
                    </div>
                    <div class="flex flex-col gap-1">
                      <div
                        class="px-2 w-fit text-white bg-green-600 rounded-md"
                      >
                        {{ getVideoQualityLabel(item.videoParams!.quality) }}
                      </div>
                      <!-- VideoParams currently disabled -->
                      <!-- <div class="px-2 w-fit text-white bg-green-600 rounded-md">
                        {{ videoDeliveryTime[item.videoParams!.deliveryTime] }}
                      </div> -->
                    </div>
                  </div>
                  <div>
                    @if (item.discountCode) {
                      <div class="text-lg text-end line-through">
                        {{ item.pricePreDiscount | currency }}
                      </div>
                    }
                    <div class="text-lg text-end">
                      {{ item.price | currency }}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            }
          }
          <div class="total big">
            <span class="text-2xl font-semibold">Total</span>
            <span class="font-bold text-2xl">{{ this.total | currency }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
