<div class="flex justify-center w-full">
  <div class="order-history-wrapper">
    <div class="flex flex-col gap-4 items-start rounded-md w-full">
      @for (order of orders$ | async; track $index) {
        <div
          class="flex flex-col border bg-stone-200 p-4 w-full gap-4 rounded-lg"
        >
          <div
            class="flex gap-1 text-start text-primary-red text-xl font-rockwell font-semibold"
          >
            Order: <span class="text-black">{{ order.orderNumber }}</span>
          </div>
          @for (item of order.orderItems; track $index) {
            <app-orderItem [orderItem]="item"></app-orderItem>
          }
          <div class="flex flex-row items-end justify-between">
            <div class="flex flex-col">
              <div class="text-lg font-bold">Order details:</div>
              <div class="flex flex-col">
                <div>
                  Date of order:
                  <span class="font-semibold">{{
                    order.createdOn | date: "shortDate"
                  }}</span>
                </div>
                <div>
                  Order number:
                  <span class="font-semibold">{{ order.orderNumber }}</span>
                </div>
                <div>
                  Order amount:
                  <span class="font-semibold">{{ order.totalAmount }}</span>
                </div>
              </div>
            </div>
            @if (order.orderStatus === orderStatus.Unpaid) {
              <div class="flex">
                <button (click)="payForOrder(order.id)" class="order-pay-button">
                  Pay for order
                </button>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>
