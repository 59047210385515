<div class="wrapper" [id]="productCardSingle.elementId">
  <div class="product-card" (click)="openProductModal()">
    <img
      class="cover"
      appImageUrl
      [iconData]="productCardSingle.productCard.data.attributes.image.data"
    />
    <div class="show-more">
      <fa-icon [icon]="faSearch" size="xl" />
      <p>More</p>
    </div>
    <div class="absolute flex flex-col justify-between h-full p-8">
      <div class="flex flex-row flex-wrap gap-4 text-white font-bold">
        <button
          class="flex flex-row gap-2 items-baseline"
          (click)="likeProject()"
          (click)="$event.stopPropagation()"
          [ngClass]="
            this.isProjectLiked ? 'pointer-events-none' : 'pointer-events-auto'
          "
        >
          <fa-icon
            [icon]="faHeart"
            size="xl"
            class="transition-colors duration-500"
            [ngClass]="
              this.isProjectLiked ? 'text-primary-red-hover' : 'text-white'
            "
          />

          <div>
            {{ this.counter }}
          </div>
        </button>
        @for (
          label of productCardSingle.productCard.data.attributes.topLabels;
          track $index
        ) {
          <app-text-and-icon [textAndIcon]="label"></app-text-and-icon>
        }
      </div>
      <div class="flex flex-col items-start gap-2">
        <div class="flex flex-row gap-2">
          @for (
            tag of productCardSingle.productCard.data.attributes.tags;
            track $index
          ) {
            <div
              class="flex flex-row items-center font-extrabold bg-lime-100 py-1 px-2 text-sm rounded-md"
            >
              <div>{{ tag.label }}</div>
            </div>
          }
        </div>
        <markdown class="text">{{
          productCardSingle.productCard.data.attributes.text
        }}</markdown>
        <div class="button">
          <app-buttons-section
            [buttonsSection]="
              productCardSingle.productCard.data.attributes.buttonsSection
            "
          ></app-buttons-section>
        </div>
      </div>
    </div>
  </div>
</div>
