<div class="relative flex video-card cursor-pointer">
  @defer {
    <div class="player">
      <video
        playsinline
        autoplay="autoplay"
        [muted]="'muted'"
        loop
        [src]="videoCard.url"
        type="video/mp4"
        [poster]="toImageUrl(videoCard.thumbnail.data)"
      ></video>
    </div>
  }
  <div
    class="absolute bg-white rounded-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-14 h-14 z-10"
  >
    <fa-icon
      [icon]="playIcon"
      class="text-primary-red absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
    />
  </div>
  <div class="absolute flex bottom-0 items-end p-4 z-10 select-none">
    <a [href]="videoCard.profileUrl" target="_blank"
      ><div class="hover:underline profile-link">
        {{ "@" + videoCard.username }}
      </div></a
    >
  </div>
</div>
