<div class="mt-2 my-2 relative">
  <div class="form-field">
    @if (!isTextArea) {
      <input
        [type]="type"
        [formControl]="formControl"
        [ngClass]="{ filled: control.value }"
      />
      <label class="pointer-events-none">{{ placeholder }}</label>
    } @else {
      <textarea
        [formControl]="formControl"
        [ngClass]="{ filled: control.value }"
      ></textarea>
    }
    <fa-icon class="valid-mark" size="sm" [icon]="faCheckCircle" />
  </div>
  @if (control.invalid && control.touched) {
    @if (control.errors?.["required"]) {
      <span class="text-red-500 text-sm">Field is required</span>
    } @else if (control.errors?.["email"]) {
      <span class="text-red-500 text-sm">Invalid Email format</span>
    } @else if (control.errors?.["minlength"]) {
      <span class="text-red-500 text-sm"
        >Minimum length is
        {{ control.errors!["minlength"]?.requiredLength }} characters</span
      >
    } @else if (control.errors?.["maxlength"]) {
      <span class="text-red-500 text-sm"
        >Maximum length is
        {{ control.errors!["maxlength"]?.requiredLength }} characters</span
      >
    } @else {
      <span class="text-red-500 text-sm">Invalid field</span>
    }
  }
</div>
