import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import UpdateProjectCountQuery from '../../queries/updateProjectLikeCounter.query';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private apollo: Apollo) {}

  updateProjectLikeCounter(id: number, counter: number) {
    const mutation = {
      mutation: UpdateProjectCountQuery,
      variables: { id: id, counter: counter },
    };

    return this.apollo.mutate(mutation).subscribe();
  }
}
