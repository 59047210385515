<div class="flex flex-col items-center gap-16">
  <div class="text-6xl font-rockwell">
    {{ comments.title }}
  </div>

  <div class="flex flex-col items-center gap-4">
    <img appImageUrl [iconData]="comments.imageTop.data" />
    <div class="flex flex-row items-center gap-8">
      <img appImageUrl [iconData]="comments.imageLeft.data" />
      <div class="grid grid-cols-2 gap-8">
        @for (
          comment of comments.comments.data.attributes.comments;
          track $index
        ) {
          <div class="flex flex-col items-center gap-8 max-w-[500px]">
            <div class="font-semibold text-2xl text-center">
              {{ comment.text }}
            </div>
            <img appImageUrl [iconData]="comment.image.data" />
          </div>
        }
      </div>
      <img appImageUrl [iconData]="comments.imageRight.data" />
    </div>
    <img appImageUrl [iconData]="comments.imageBottom.data" />
  </div>
  <div class="flex flex-col gap-2">
    <app-buttons-section
      [buttonsSection]="comments.buttonsSection"
    ></app-buttons-section>
  </div>
</div>
