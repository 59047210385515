import { ButtonLinkData } from '../componentsData/buttonLink.componentData';
import { ButtonsSectionData } from '../componentsData/buttonsSection.componentData';

export const ProductInfoSection = `
          ... on ComponentSectionsProductInfo {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            imageUrl
            imageOnRight
            bgImage {
              data {
                attributes {
                  url
                }
              }
            }
            text
            videoButton {
              ${ButtonLinkData}
            }
              buttonsSection {
    ${ButtonsSectionData}
  }
          }
`;
