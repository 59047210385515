import { gql } from 'apollo-angular';
import { HeaderData } from './componentsData/header.componentData';
import { BadgesSection } from './sections/badgesSection';
import { ProductCardSingleSection } from './sections/productCardSingleSection';
import { ImageMarkdownSection } from './sections/imageMarkdownSection';
import { ReferenceCardsSection } from './sections/referenceCardSection';
import { VideoCardsSection } from './sections/videoCardsSection';
import { FancyboxCardsSection } from './sections/fancyboxCardsSection';
import { QuotesSection } from './sections/quotesSection';
import { InstagramPostsSection } from './sections/instagramPostsSection';
import { FreeTextSection } from './sections/freeTextSection';
import { ProductInfoSection } from './sections/ProductInfoSection';
import { ScrollingTextSection } from './sections/scrollingTextSection';
import { CommentsSection } from './sections/commentsSection';
import { InstagramPostsOnlySection } from './sections/instagramPostsOnlySection';
import { PricingTableSection } from './sections/pricingTableSection';
import { TextWithInitialSection } from './sections/textWithInitialSection';
import { FAQSection } from './sections/faqSection';
import { OrderSummarySection } from './sections/order/orderSummarySection';
import { BasketSummarySection } from './sections/basket/basketSummarySection';
import { AccountDetailsSection } from './sections/account/accountDetailsSection';
import { OrderHistorySection } from './sections/account/orderHistorySection';
import { ThankYouBadgeSection } from './sections/thankYouBadgeSection';
import { FeedbackSection } from './sections/feedbackSection';
import { ElfiTeamSection } from './sections/elfiTeamSection';

const PageQuery = gql`
  query Page($url: String) {
    pages(filters: { url: { eq: $url } }) {
      data {
        attributes {
          title
          header {
            ${HeaderData}
          }
          Sections {
            ${BadgesSection}
            ${ProductCardSingleSection}
            ${ImageMarkdownSection}
            ${ReferenceCardsSection}
            ${VideoCardsSection}
            ${FancyboxCardsSection}
            ${QuotesSection}
            ${InstagramPostsSection}
            ${InstagramPostsOnlySection}
            ${FreeTextSection}
            ${ProductInfoSection}
            ${ScrollingTextSection}
            ${CommentsSection}
            ${PricingTableSection}
            ${TextWithInitialSection}
            ${FAQSection}
            ${BasketSummarySection}
            ${OrderSummarySection}
            ${AccountDetailsSection}
            ${OrderHistorySection}
            ${ThankYouBadgeSection}
            ${FeedbackSection}
            ${ElfiTeamSection}
          }
        }
      }
    }
  }
`;

export default PageQuery;
