import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../../config.service';
import { ThankYouBadge } from '../../../models/components/sections/thankYouBadge.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';

@Component({
  standalone: true,
  selector: 'app-thank-you-badge',
  templateUrl: './thank-you-badge.component.html',
  styleUrls: ['./thank-you-badge.component.css'],
  imports: [ImageUrlDirective]
})
export class ThankYouBadgeComponent {
  @Input() thankYouBadge: ThankYouBadge;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }
}
