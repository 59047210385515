<div class="flex flex-col items-center gap-12">
  <div class="pricing-table">
    @for (pricingSection of pricingTable.pricingSections; track $index) {
      <div class="section">
        <markdown>{{ pricingSection.table }}</markdown>
      </div>
    }
  </div>
  <app-buttons-section
    [buttonsSection]="pricingTable.buttonsSection"
  ></app-buttons-section>
</div>
