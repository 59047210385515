import { ButtonLinkData } from "./buttonLink.componentData";

export const ButtonsSectionData = `
  data {
    attributes {
      buttons {
        ${ButtonLinkData}
      }
    }
}
`;