import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { AppConfigService } from '../../../config.service';
import { InformationTabs } from '../../../models/components/products/informationTabs.model';
import { Tab } from '../../../models/components/products/tab.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';

@Component({
  standalone: true,
  selector: 'app-information-tabs',
  templateUrl: './information-tabs.component.html',
  styleUrls: ['./information-tabs.component.css'],
  imports: [CommonModule, MarkdownModule, ImageUrlDirective],
})
export class InformationTabsComponent {
  @Input() informationTabs: InformationTabs;
  selectedTab: Tab | null;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }



  selectTab(tab: Tab) {
    if (this.selectedTab === tab) {
      this.selectedTab = null;
    } else {
      this.selectedTab = tab;
    }
  }
}
