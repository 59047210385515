import { OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  Input,
  OnInit,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { AppConfigService } from '../../../config.service';
import { VideoCard } from '../../../models/components/videoCard.model';
import { ComponentEntry } from '../../../models/strapi/componentEnty.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';
import { createImageUrl } from '../../../shared/utils/create-image-url';

export const INSTAGRAM_VIDEO_MODAL_REF = new InjectionToken<OverlayRef>(
  'VIDEO_MODAL_REF',
);

@Component({
  selector: 'app-instagram-video-modal',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, ImageUrlDirective],
  templateUrl: './instagram-video-modal.component.html',
  styleUrl: './instagram-video-modal.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstagramVideoModalComponent implements OnInit {
  @Input() currentVideoCard: VideoCard;
  @Input() videoCards: VideoCard[];

  readonly chevronDownIcon = faChevronDown;
  readonly chevronUpIcon = faChevronUp;
  readonly timesIcon = faTimes;

  private readonly overlayRef = inject(INSTAGRAM_VIDEO_MODAL_REF);
  private readonly configService = inject(AppConfigService);

  strapiUrl: string;
  videoCardIndex = 0;

  constructor() {
    this.strapiUrl = this.configService.getConfigOrThrow().strapiUrl;
  }
  ngOnInit(): void {
    this.videoCardIndex = this.videoCards.indexOf(this.currentVideoCard);
  }

  nextCard() {
    this.videoCardIndex = (this.videoCardIndex + 1) % this.videoCards.length;
    this.currentVideoCard = this.videoCards[this.videoCardIndex];
  }

  previousCard() {
    this.videoCardIndex =
      (this.videoCardIndex - 1 + this.videoCards.length) %
      this.videoCards.length;
    this.currentVideoCard = this.videoCards[this.videoCardIndex];
  }

  closePopup() {
    this.overlayRef.dispose();
  }
  toImageUrl(iconData: ComponentEntry<any>) {
    return createImageUrl(iconData, this.strapiUrl);
  }
}
