import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, of, switchMap, tap } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly apiUrl = `${environment.apiUrl}`;

    private userSubject = new BehaviorSubject<User | null>(null);
    user$ = this.userSubject.asObservable();

    constructor(private http: HttpClient) {
        this.tryGetUser().subscribe();
    }

    login(email: string, password: string) {
        return this.http.post<Response>(`${this.apiUrl}/login/?useCookies=true`, {
            email: email,
            password: password
        });
    }

    register(request: RegisterRequest) {
        return this.http.post<Response>(`${this.apiUrl}/register`, request
        ).pipe(switchMap(() => this.login(request.email, request.password)));
    }

    logout() {
        return this.http.post<Response>(`${this.apiUrl}/logout`, null,
        ).pipe(tap(() => this.userSubject.next(null)));
    }

    sendNewPassword(email: string) {
        const request = {
            email: email
        };

        return this.http.post<Response>(`${this.apiUrl}/forgotPassword`, request
        );
    }

    tryGetUser() {
        return this.isLogged().pipe(
            switchMap(isLogged => {
                if (isLogged) {
                    return this.getUser();
                }
                else {
                    return of(null);
                }
            }),
            catchError(() => of(null))
        );
    }

    updateUser(request: UpdateUserRequest) {
        return this.http.post<User>(`${this.apiUrl}/manage/info`, request).pipe(
            tap(user => {
                this.userSubject.next(user);
            }));
    }

    deleteAccount() {
        return this.http.post(`${this.apiUrl}/manage/deleteAccount`, null).pipe(
            tap(() => {
                this.userSubject.next(null);
            }));
    }

    private isLogged(): Observable<boolean> {
        return this.http.get<boolean>(`${this.apiUrl}/isLogged`);
    }

    private getUser(): Observable<User> {
        return this.http.get<User>(`${this.apiUrl}/manage/info`).pipe(
            tap(user => {
                this.userSubject.next(user);
            }));
    }
}

export interface RegisterRequest {
    firstName: string;
    email: string;
    password: string;
    newsletterAccepted: boolean;
}

export interface User {
    firstName: string;
    email: string;
    lastName?: string;
    street?: string;
    postalCode?: string;
    city?: string;
}

export interface UpdateUserRequest {
    firstName: string;
    lastName?: string;
    street?: string;
    postalCode?: string;
    city?: string;
    oldPassword?: string;
    newPassword?: string;
}

export interface Response {
    isSuccess: boolean;
    message: string;
}
