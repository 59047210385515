import { animate, style, transition, trigger } from "@angular/animations";
import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, signal } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { ButtonComponent } from "../../../core/button/button.component";
import { Quote } from "../../../models/components/quote.model";
import { Quotes } from "../../../models/components/sections/quotes.model";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";

@Component({
  standalone: true,
  selector: "app-quotes",
  templateUrl: "./quotes.component.html",
  styleUrls: ["./quotes.component.css"],
  imports: [CommonModule, ButtonComponent, MarkdownModule, FontAwesomeModule, ImageUrlDirective],
  animations: [
    trigger("fade", [
      transition(":leave", [
        style({ opacity: 1 }),
        animate("0.2s ease-in-out", style({ opacity: 0 })),
      ]),
      transition(":enter", [
        style({ opacity: 0 }),
        animate("0.2s ease-in-out", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class QuotesComponent implements OnInit {
  @Input() quotes: Quotes;
  readonly chevronLeftIcon = faChevronLeft;
  readonly chevronRightIcon = faChevronRight;
  strapiUrl: string;
  currentQuote = signal<Quote | undefined>(undefined);
  currentQuoteIndex = signal<number>(0);

  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  ngOnInit(): void {
    this.currentQuote.set(this.quotes.quotes[0]);
  }

  nextQuote() {
    this.currentQuoteIndex.set(
      (this.currentQuoteIndex() + 1) % this.quotes.quotes.length
    );
    this.currentQuote.set(undefined);
    setTimeout(() => {
      this.currentQuote.set(this.quotes.quotes[this.currentQuoteIndex()]);
    }, 200);
  }

  previousQuote() {
    this.currentQuoteIndex.set(
      (this.currentQuoteIndex() - 1 + this.quotes.quotes.length) %
      this.quotes.quotes.length
    );
    this.currentQuote.set(undefined);
    setTimeout(() => {
      this.currentQuote.set(this.quotes.quotes[this.currentQuoteIndex()]);
    }, 200);
  }
}
