import { ButtonLinkData } from '../componentsData/buttonLink.componentData';
import { ButtonsSectionData } from '../componentsData/buttonsSection.componentData';

export const ProductCardSingleSection = `
          ... on ComponentSectionsProductCardSingle {
            elementId
            productCard {
              data {
                attributes {
                  likeCounter {
                    data {
                      attributes {
                        counter
                        icon {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  playIcon {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  text
                  videoUrl
                  additionalInfo
                  topLabels {
                    text
                    icon {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    iconOnRight
                  }
                  tags {
                    label
                  }
                  buttonsSection {
                    ${ButtonsSectionData}
                  }
                  productOptions {
                    label
                    button {
                      ${ButtonLinkData}
                    }
                  }
                  informationTabs {
                    openIcon {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    closeIcon {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    tabs {
                      title
                      text
                    }
                  }
                }
              }
            }
          }
`;
