<div class="flex flex-col items-center gap-16">
  <div class="text-3xl text-red-600 font-rockwell font-semibold">
    {{ elfiTeam.title }}
  </div>
  <div class="grid grid-cols-4 gap-16">
    @for (member of elfiTeam.elfiMembers; track $index) {
      <div class="flex flex-col items-center gap-2">
        <img
          appImageUrl
          [iconData]="member.image.data"
          class="h-24 rounded-full"
        />
        <div class="font-rockwell">{{ member.name }}</div>
        <div class="text-gray-500">{{ member.description }}</div>
      </div>
    }
  </div>
  <img appImageUrl [iconData]="elfiTeam.image.data" class="" />
</div>
