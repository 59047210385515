<div class="wrapper">
  <markdown class="text">{{ instagramPosts.text }}</markdown>
  <div class="relative w-full">
    <div class="posts-wrapper" #postsWrapper>
      @for (post of instagramPosts.posts.data; track $index) {
        <app-instagram-post
          [instagramPost]="post.attributes"
          [allPosts]="instagramPosts"
        ></app-instagram-post>
      }
    </div>
    <button class="move-button left" (click)="previous()">
      <fa-icon
        [icon]="chevronLeftIcon"
        class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </button>
    <button class="move-button right" (click)="next()">
      <fa-icon
        [icon]="chevronRightIcon"
        class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </button>
  </div>

  @if (instagramPosts.companies.data) {
    <div
      class="flex flex-row flex-wrap justify-center items-center gap-8 sm:gap-16"
    >
      <div class="flex font-bold text-xl opacity-20">
        {{ instagramPosts.companies.data.attributes.text }}
      </div>
      @for (
        company of instagramPosts.companies.data.attributes.companies;
        track $index
      ) {
        <a [href]="company.url">
          <img
            appImageUrl
            [iconData]="company.image.data"
            class="object-contain h-8 sm:h-10 opacity-20 hover:opacity-100"
          />
        </a>
      }
    </div>
  }
  <app-buttons-section
    [buttonsSection]="instagramPosts.buttonsSection"
  ></app-buttons-section>
</div>
