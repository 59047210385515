import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../config.service';
import { ButtonsSection } from '../../models/collectionTypes/buttonsSection.model';
import { ComponentData } from '../../models/strapi/componentData.model';
import { ButtonComponent } from '../button/button.component';

@Component({
  standalone: true,
  selector: 'app-buttons-section',
  templateUrl: './buttons-section.component.html',
  styleUrls: ['./buttons-section.component.css'],
  imports: [ButtonComponent],
})
export class ButtonsSectionComponent {
  @Input() buttonsSection: ComponentData<ButtonsSection>;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }
}
