<div class="flex flex-col items-center w-full">
  <div class="relative flex flex-col">
    <img appImageUrl [iconData]="thankYouBadge.image.data" />
    <div
      class="absolute flex flex-col items-center justify-center font-rockwell text-white text-3xl w-full h-full"
    >
      <div class="pr-40 pb-6">{{ thankYouBadge.text }}</div>
    </div>
  </div>
</div>
