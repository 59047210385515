import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { AppConfigService } from "../../../config.service";
import { VideoCard } from "../../../models/components/videoCard.model";
import { ComponentEntry } from "../../../models/strapi/componentEnty.model";
import { createImageUrl } from "../../../shared/utils/create-image-url";

@Component({
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.css'],
})
export class VideoCardComponent {
  @Input() videoCard: VideoCard;
  readonly playIcon = faPlay;
  strapiUrl: string;


  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  toImageUrl(iconData: ComponentEntry<any>) {
    return createImageUrl(iconData, this.strapiUrl);
  }
}
