import { OverlayRef } from "@angular/cdk/overlay";
import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnInit,
} from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { InstagramPost } from "../../../models/collectionTypes/instagramPost.model";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";

export const INSTAGRAM_POST_REF = new InjectionToken<OverlayRef>(
  "INSTAGRAM_POST_REF"
);

@Component({
  standalone: true,
  selector: 'app-instagram-post-modal',
  templateUrl: './instagram-post-modal.component.html',
  styleUrls: ['./instagram-post-modal.component.css'],
  imports: [MarkdownModule, FontAwesomeModule, ImageUrlDirective],
})
export class InstagramPostModalComponent implements OnInit {
  @Input() instagramPost: InstagramPost;
  @Input() allPosts: InstagramPost[];
  readonly timesIcon = faTimes;
  readonly chevronLeftIcon = faChevronLeft;
  readonly chevronRightIcon = faChevronRight;
  strapiUrl: string;
  postIndex = 0;

  constructor(
    @Inject(INSTAGRAM_POST_REF) public overlayRef: OverlayRef,
    private readonly configService: AppConfigService
  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  ngOnInit(): void {
    this.postIndex = this.allPosts.indexOf(this.instagramPost);
  }

  nextCard() {
    this.postIndex = (this.postIndex + 1) % this.allPosts.length;
    this.instagramPost = this.allPosts[this.postIndex];
  }

  previousCard() {
    this.postIndex =
      (this.postIndex - 1 + this.allPosts.length) % this.allPosts.length;
    this.instagramPost = this.allPosts[this.postIndex];
  }

  closePopup() {
    this.overlayRef.dispose();
  }
}
