import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { OrderItem } from '../../../../models/order/order.model';
import { PriceListService } from '../../../../services/price-lists.service';
import { ProductType } from '../../../video-generator-form/models/video-form-config';
import { DeliveryTimeOption, QualityOption } from '../../../video-generator-form/models/video-params.model';

@Component({
  standalone: true,
  selector: 'app-orderItem',
  templateUrl: './orderItem.component.html',
  styleUrls: ['./orderItem.component.css'],
  imports: [CommonModule, FontAwesomeModule]
})
export class OrderItemComponent {
  @Input() orderItem: OrderItem;

  qualityOptions: QualityOption[];
  deliveryTimeOptions: DeliveryTimeOption[];

  constructor(private readonly priceListService: PriceListService) { }

  qualityOption: QualityOption;
  deliveryTimeOption: DeliveryTimeOption;

  readonly faPlayCircle = faPlayCircle;
  readonly faDownload = faDownload;
  readonly productType = ProductType;

  ngOnInit() {
    if (this.orderItem.video) {
      this.qualityOption = this.priceListService.getQualityOptions().find(x => x.value == this.orderItem.video!.videoParams.quality)!;
      this.deliveryTimeOption = this.priceListService.getDeliveryTimeOptions().find(x => x.value == this.orderItem.video!.videoParams.deliveryTime)!;
    }
  }
}
