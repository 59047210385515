export const FeedbackSection = `
          ... on ComponentSectionsFeedback {
            title
            feedbackList {
              data {
                attributes {
                  cards {
                    text
                    name
                    date
                    rating
                  }
                  flag {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ratingIcon {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
`;
