export enum HeaderSize {
  percent_100 = 100,
  percent_90 = 90,
  percent_80 = 80,
  percent_70 = 70,
  percent_60 = 60,
  percent_50 = 50,
  percent_40 = 40,
  percent_30 = 30,
  percent_20 = 20,
  percent_10 = 10,
}
