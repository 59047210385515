import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { OrderHistory } from '../../../../models/components/account/orderHistory.model';
import { OrderService } from '../../../../services/order.service';
import { Order } from '../../../../models/order/order.model';
import { OrderItemComponent } from "../orderItem/orderItem.component";
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { OrderStatus } from '../../../../models/order/enums/orderStatus.enum';
import { OrderPaymentRequest } from '../../../../models/order/order-contract.model';
import { Router } from '@angular/router';
import { RouteUrls } from '../../../../shared/enums/route-urls.enum';

@Component({
  standalone: true,
  selector: 'app-orderHistory',
  templateUrl: './orderHistory.component.html',
  styleUrls: ['./orderHistory.component.css'],
  imports: [CommonModule, OrderItemComponent],
})
export class OrderHistoryComponent implements OnInit {
  @Input() orderHistory: OrderHistory;
  orders$: Observable<Order[]>;
  orderStatus = OrderStatus;

  constructor(private orderService: OrderService,
    private readonly router: Router
  ) { }

  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.orders$ = this.orderService.getOrders().pipe(
      takeUntil(this.destroy$),
      map((response) => {
        return response.orders;
      }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  payForOrder(orderId: number) {
    this.router.navigate([`${RouteUrls.Order}/${orderId}`]);
  }
}
