import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { ButtonComponent } from "../../../core/button/button.component";
import { ButtonsSectionComponent } from "../../../core/buttons-section/buttons-section.component";
import { ImageMarkdown } from "../../../models/components/sections/imageMarkdown.model";
import { ComponentEntry } from "../../../models/strapi/componentEnty.model";
import { ResponsiveService } from "../../../services/responsive.service";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";
import { createImageUrl } from "../../../shared/utils/create-image-url";

@Component({
  standalone: true,
  selector: 'app-image-markdown',
  templateUrl: './image-markdown.component.html',
  styleUrls: ['./image-markdown.component.css'],
  imports: [
    MarkdownModule,
    ButtonComponent,
    CommonModule,
    ButtonsSectionComponent,
    ImageUrlDirective
  ],
})
export class ImageMarkdownComponent {
  @Input() imageMarkdown: ImageMarkdown;
  strapiUrl: string;
  private readonly responsiveService = inject(ResponsiveService);

  isMobileView = computed(
    () => this.responsiveService.viewportSize().width < 640
  );

  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }
  toImageUrl(iconData: ComponentEntry<any>) {
    return createImageUrl(iconData, this.strapiUrl);
  }
}
