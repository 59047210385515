<div class="flex flex-row gap-2">
  @switch (this.buttonLink.type) {
    @case (buttonType.primary) {
      <button
        [routerLink]="this.buttonLink.link.data.attributes.url"
        class="primary-button py-4"
      >
        {{ this.buttonLink.label }}
      </button>
    }
    @case (buttonType.secondary) {
      <div
        class="flex flex-row items-center gap-2 cursor-pointer hover:underline"
        [routerLink]="this.buttonLink.link.data.attributes.url"
      >
        @if (!this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-12" />
        }
        <div class="flex font-semibold">
          {{ this.buttonLink.label }}
        </div>
        @if (this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-12" />
        }
      </div>
    }
    @case (buttonType.text) {
      <markdown class="text-xl text-gray-400 font-bold">{{
        this.buttonLink.text
      }}</markdown>
    }
    @case (buttonType.navbar) {
      <a
        [routerLink]="this.buttonLink.link.data.attributes.url"
        class="navbar-button"
      >
        {{ buttonLink.label }}
      </a>
    }
    @case (buttonType.basket) {
      <div
        class="flex flex-row items-center gap-2 text-inherit text-lg font-bold p-2 rounded-md bg-opacity-0 bg-white hover:bg-opacity-15 cursor-pointer"
        (click)="openBasket()"
      >
        <div>
          {{ buttonLink.label }}
        </div>
        <img appImageUrl [iconData]="buttonLink.icon.data" class="h-5" />
      </div>
    }
    @case (buttonType.videoPrimary) {
      <div
        class="flex flex-row items-center gap-2 cursor-pointer text-inherit"
        (click)="openVideoModal()"
      >
        @if (!this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-10" />
        }
        <div class="video-primary-button-text">
          {{ this.buttonLink.label }}
        </div>
        @if (this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-10" />
        }
      </div>
    }
    @case (buttonType.videoSecondary) {
      <div
        class="flex flex-row items-center gap-2 cursor-pointer"
        (click)="openVideoModal()"
      >
        @if (!this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-6" />
        }
        <div class="flex font-semibold text-red-600">
          {{ this.buttonLink.label }}
        </div>
        @if (this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-6" />
        }
      </div>
    }
    @case (buttonType.scrollToElementPrimary) {
      <button
        (click)="scrollToElement(this.buttonLink.elementId)"
        class="row-start-2 col-start-1 bg-red-600 text-white py-4 px-8 rounded-2xl"
      >
        {{ this.buttonLink.label }}
      </button>
    }
    @case (buttonType.accordion) {
      <div class="flex flex-row items-start gap-2 cursor-pointer">
        @if (!this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-12" />
        }
        <div class="flex flex-col items-start">
          <div
            class="flex font-semibold text-red-600 hover:underline"
            (click)="toggleAccordion()"
          >
            {{ this.buttonLink.label }}
          </div>
          @if (accordionShow) {
            <markdown>{{ this.buttonLink.text }}</markdown>
          }
        </div>
        @if (this.buttonLink.iconOnRight) {
          <img appImageUrl [iconData]="buttonLink.icon.data" class="w-12" />
        }
      </div>
    }
  }
</div>
