import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import {
  CreateOrderRequest,
  CreateOrderResponse,
  GetOrderResponse,
  GetOrdersResponse,
  OrderPaymentRequest,
  OrderPaymentResponse
} from '../models/order/order-contract.model';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private readonly apiUrl = `${environment.apiUrl}/Orders`;

  constructor(private http: HttpClient) {}

  getOrders(): Observable<GetOrdersResponse> {
    return this.http.get<GetOrdersResponse>(`${this.apiUrl}`);
  }

  getOrder(orderId: string): Observable<GetOrderResponse> {
    return this.http.get<GetOrderResponse>(`${this.apiUrl}/${orderId}`);
  }

  createOrder(request: CreateOrderRequest): Observable<CreateOrderResponse> {
    return this.http.post<CreateOrderResponse>(
      `${this.apiUrl}/Create`,
      request,
    );
  }

  payForOrder(request: OrderPaymentRequest): Observable<OrderPaymentResponse> {
    return this.http.post<OrderPaymentResponse>(`${this.apiUrl}/Pay`, request);
  }
}
