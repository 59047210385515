import { CommonModule } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { MarkdownModule } from 'ngx-markdown';
import { AppConfigService } from '../../../config.service';
import { ButtonsSectionComponent } from '../../../core/buttons-section/buttons-section.component';
import { ProductInfo } from '../../../models/components/sections/productInfo.model';
import { ComponentEntry } from '../../../models/strapi/componentEnty.model';
import { ResponsiveService } from '../../../services/responsive.service';
import { SafePipe } from '../../../shared/pipes/safe-pipe';
import { createImageUrl } from '../../../shared/utils/create-image-url';

@Component({
  standalone: true,
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.css'],
  imports: [
    MarkdownModule,
    CommonModule,
    ButtonsSectionComponent,
    FontAwesomeModule,
    SafePipe
  ],
})
export class ProductInfoComponent {
  @Input() productInfo: ProductInfo;
  readonly playIcon = faPlay;
  readonly responsiveService = inject(ResponsiveService);
  strapiUrl: string;
  isVideoPaused = true;

  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  isNarrowerView = computed(
    () => this.responsiveService.viewportSize().width < 960,
  );

  toImageUrl(iconData: ComponentEntry<any>) {
    return createImageUrl(iconData, this.strapiUrl);
  }
}
