<div class="w-full flex flex-col gap-2 items-end">
  @if (showInput) {
    <div class="flex flex-row">
      <div class="flex flex-col gap-2">
        <div class="flex flex-row">
          <input
            [placeholder]="promoCodeInput.placeholder"
            class="p-2"
          />
          <button class="bg-black text-white p-2" (click)="applyPromoCode()">
            {{promoCodeInput.buttonLabel}}
          </button>
        </div>
      </div>
    </div>
  } @else {
    <div class="flex text-red-600 hover:underline cursor-pointer" (click)="toggleInput()">
      {{ promoCodeInput.label }}
    </div>
  }
</div>
