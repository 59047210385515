<div class="absolute inset-0 z-50">
  <img
    appImageUrl
    [iconData]="currentVideoCard.thumbnail.data"
    class="object-cover fixed w-full h-full"
  />
  <div
    class="fixed bg-black bg-opacity-75 backdrop-blur-lg h-full w-full flex place-items-center place-content-center"
  >
    <video
      class="h-full"
      playsinline
      autoplay="autoplay"
      controls
      loop
      [src]="currentVideoCard.url"
      type="video/mp4"
      [poster]="toImageUrl(currentVideoCard.thumbnail.data)"
    ></video>

    <div class="buttons">
      <button class="change-button" (click)="closePopup()">
        <fa-icon
          [icon]="timesIcon"
          class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </button>
      <div class="flex flex-col gap-4">
        <button class="change-button" (click)="previousCard()">
          <fa-icon
            [icon]="chevronUpIcon"
            class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </button>
        <button class="change-button" (click)="nextCard()">
          <fa-icon
            [icon]="chevronDownIcon"
            class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </button>
      </div>
    </div>
  </div>
</div>
