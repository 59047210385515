import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../../config.service';
import { ElfiTeam } from '../../../models/components/sections/elfiTeam.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';

@Component({
  standalone: true,
  selector: 'app-elfi-team',
  templateUrl: './elfi-team.component.html',
  styleUrls: ['./elfi-team.component.css'],
  imports: [CommonModule, ImageUrlDirective],
})
export class ElfiTeamComponent {
  @Input() elfiTeam: ElfiTeam;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

}
