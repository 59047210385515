import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { ButtonComponent } from "../../../core/button/button.component";
import { ButtonsSectionComponent } from "../../../core/buttons-section/buttons-section.component";
import { Header } from "../../../models/components/header/header.model";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";
import { HeaderSize } from "../../../shared/enums/headerSize.enum";

@Component({
  standalone: true,
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  imports: [
    MarkdownModule,
    CommonModule,
    ButtonComponent,
    ButtonsSectionComponent,
    ImageUrlDirective,
  ],
})
export class HeaderComponent {
  @Input() header: Header;
  readonly headerSize = HeaderSize;
  strapiUrl: string;

  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }
}
