export const ReferenceCardsSection = `
... on ComponentSectionsReferenceCards {
  cards {
    icon {
      data {
        attributes {
          url
        }
      }
    }
    title
    description
    tooltip
    elementId
  }
}
`;
