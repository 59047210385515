import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../../config.service';
import { InstagramPostsOnly } from '../../../models/components/sections/instagramPostsOnly.model';
import { InstagramPostComponent } from '../../elements/instagram-post/instagram-post.component';

@Component({
  standalone: true,
  selector: 'app-instagram-posts-only',
  templateUrl: './instagram-posts-only.component.html',
  styleUrls: ['./instagram-posts-only.component.css'],
  imports: [InstagramPostComponent],
})
export class InstagramPostsOnlyComponent {
  @Input() instagramPostsOnly: InstagramPostsOnly;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

}
