import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCheckCircle,  } from "@fortawesome/free-solid-svg-icons";
import { DiscountCode } from "../../../models/discountCode/discount-code.model";
import { DiscountType } from "../../../models/discountCode/enums/discountType.enum";
import { UsageType } from "../../../models/discountCode/enums/usageType.enum";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: "app-code-input",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule],
  templateUrl: "./code-input.component.html",
  styleUrl: "./code-input.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeInputComponent {
  readonly faCheckCircle = faCheckCircle;
  readonly faTrash = faTrashAlt;

  discountCode = input<DiscountCode | undefined>();
  onCodeApply = output<string>();
  onCodeRemove = output<void>();

  control = new FormControl<string>("");

  constructor() {
    effect(() => {
      if (this.discountCode()?.code) {
        this.control.patchValue(this.discountCode()!.code);
        this.control.markAsDirty();
        this.control.disable();
      } else {
        this.control.enable();
        this.control.patchValue("");
      }
    });
  }

  handleButtonClick() {
    if (this.discountCode()) {
      this.onCodeRemove.emit();
    } else {
      this.onCodeApply.emit(this.control.value!);
    }
  }
}
