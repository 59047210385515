import { ButtonsSectionData } from '../componentsData/buttonsSection.componentData';

export const PricingTableSection = `
          ... on ComponentSectionsPricingTable {
            buttonsSection {
              ${ButtonsSectionData}
            }
            pricingSections {
              title
              table
            }
          }
`;
