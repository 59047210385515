import { Component, Input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { TextWithInitial } from '../../../models/components/sections/TextWithInitial.model';

@Component({
  standalone: true,
  selector: 'app-text-with-initial',
  templateUrl: './text-with-initial.component.html',
  styleUrls: ['./text-with-initial.component.css'],
  imports: [MarkdownModule],
})
export class TextWithInitialComponent {
  @Input() textWithInitial: TextWithInitial;
}
