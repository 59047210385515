<div class="flex flex-col gap-4">
  @for (card of feedbackList.cards; track $index) {
    <div
      class="flex flex-col gap-2 bg-stone-100 border text-sm max-w-[800px] p-4"
    >
      <div>{{ card.text }}</div>
      <div class="flex flex-row items-center gap-2">
        <img appImageUrl [iconData]="feedbackList.flag.data" class="h-4" />
        <div class="font-semibold">{{ card.name }},</div>
        <div class="font-semibold">{{ card.date | date: "shortDate" }}</div>
        <img
          appImageUrl
          [iconData]="feedbackList.ratingIcon.data"
          class="h-4"
        />
      </div>
    </div>
  }
</div>
