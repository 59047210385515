import { ComponentEntry } from "../../models/strapi/componentEnty.model";

//support for external images and images hosted on strapi
export function createImageUrl(iconData: ComponentEntry<any>, strapiUrl: string) {
    let url = '';

    if (iconData) {
        if (iconData.attributes.url.startsWith('http')) {
            url = iconData.attributes.url;
        }
        else
            url = strapiUrl + iconData.attributes.url;
    }
    return url;
}