import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../../config.service';
import { TextAndIcon } from '../../../models/components/products/textAndIcon.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';

@Component({
  standalone: true,
  selector: 'app-text-and-icon',
  templateUrl: './text-and-icon.component.html',
  styleUrls: ['./text-and-icon.component.css'],
  imports: [ImageUrlDirective],
})
export class TextAndIconComponent {
  @Input() textAndIcon: TextAndIcon;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

}
