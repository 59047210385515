import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { GetDiscountCodeResponse } from '../models/discountCode/discount-code-contract.model';

@Injectable({
  providedIn: 'root',
})
export class DiscountCodesService {
  private readonly apiUrl = `${environment.apiUrl}/DiscountCodes`;

  constructor(private http: HttpClient) { }

  getDiscountCode(code: string): Observable<GetDiscountCodeResponse> {
    const params = {
      code: code,
    };

    return this.http.get<GetDiscountCodeResponse>(`${this.apiUrl}`, { params });
  }
}
