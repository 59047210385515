export const ButtonLinkData = `
label
type
iconOnRight
elementId
text
icon {
  data {
    attributes {
      url
    }
  }
}
link {
  data {
    attributes {
      url
    }
  }
}
`;
