import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ButtonsSectionComponent } from '../../../core/buttons-section/buttons-section.component';
import { FAQ } from '../../../models/components/sections/faq.model';
import { AccordionComponent } from "../../elements/accordion/accordion.component";

@Component({
  standalone: true,
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  imports: [CommonModule, ButtonsSectionComponent, AccordionComponent],
})
export class FaqComponent {
  @Input() faq: FAQ;
}
