import { ButtonLinkData } from '../componentsData/buttonLink.componentData';

export const VideoCardsSection = `
          ... on ComponentSectionsVideoCards {
            title
            button {
              ${ButtonLinkData}
            }
            cards {
              url
              username
              profileUrl
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
              previewGIF {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
`;
