<div class="flex flex-col items-center gap-8 w-full mt-12">
  <markdown class="title">
    {{ videoCards.title }}
  </markdown>
  <div class="flex flex-row gap-4 relative">
    @for (videoCard of cardsView(); track $index) {
      <app-video-card [videoCard]="videoCard" (click)="openVideoModal(videoCard)" />
    }
    <div class="move-button left" (click)="previousCard()">
      <fa-icon
        [icon]="chevronLeftIcon"
        class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </div>
    <div class="move-button right" (click)="nextCard()">
      <fa-icon
        [icon]="chevronRightIcon"
        class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </div>
  </div>
  <app-button [buttonLink]="videoCards.button"></app-button>
</div>
