import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss'
})
export class TextInputComponent {
  @Input() control: AbstractControl;
  @Input() placeholder: string;
  @Input() type: string = 'text';
  @Input() isTextArea: boolean = false;

  readonly faCheckCircle = faCheckCircle;

  get formControl(): FormControl {
    return this.control as FormControl;
  }
}
