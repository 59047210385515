<div class="flex flex-col items-start gap-12 bg-neutral-100 p-8 md:p-24">
  <div class="flex flex-col gap-12">
    @for (question of faq.questions; track $index) {
      <app-accordion [accordion]="question"></app-accordion>
    }
  </div>
  <app-buttons-section
    [buttonsSection]="faq.buttonsSection"
  ></app-buttons-section>
</div>
