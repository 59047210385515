import { Component, Input } from '@angular/core';
import { FreeText } from '../../../models/components/sections/freeText.model';
import { MarkdownModule } from 'ngx-markdown';
import { ButtonComponent } from "../../../core/button/button.component";
import { ButtonsSectionComponent } from "../../../core/buttons-section/buttons-section.component";

@Component({
  standalone: true,
  selector: 'app-freeText',
  templateUrl: './freeText.component.html',
  styleUrls: ['./freeText.component.css'],
  imports: [MarkdownModule, ButtonComponent, ButtonsSectionComponent]
})
export class FreeTextComponent {
  @Input() freeText: FreeText;
}
