import { ButtonsSectionData } from './buttonsSection.componentData';

export const HeaderData = `
          image {
            data {
              attributes {
                url
              }
            }
          }
          size
          url
          title
          description
            buttonsSection {
    ${ButtonsSectionData}
  }
`;
