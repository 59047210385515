import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { AppConfigService } from '../../config.service';
import { BasketSummaryComponent } from '../../containers/basket/basket-summary/basket-summary.component';
import { BasketItemsService } from '../../containers/basket/services/basketItems.service';
import { HeaderComponent } from '../../containers/elements/header/header.component';
import { AccountDetailsComponent } from '../../containers/sections/account/accountDetails/accountDetails.component';
import { OrderHistoryComponent } from '../../containers/sections/account/orderHistory/orderHistory.component';
import { BadgesComponent } from '../../containers/sections/badges/badges.component';
import { CommentsComponent } from '../../containers/sections/comments/comments.component';
import { ElfiTeamComponent } from '../../containers/sections/elfi-team/elfi-team.component';
import { FancyboxCardsComponent } from '../../containers/sections/fancybox-cards/fancybox-cards.component';
import { FaqComponent } from '../../containers/sections/faq/faq.component';
import { FeedbackComponent } from '../../containers/sections/feedback/feedback.component';
import { FreeTextComponent } from '../../containers/sections/freeText/freeText.component';
import { ImageMarkdownComponent } from '../../containers/sections/image-markdown/image-markdown.component';
import { InstagramPostsOnlyComponent } from '../../containers/sections/instagram-posts-only/instagram-posts-only.component';
import { InstagramPostsComponent } from '../../containers/sections/instagram-posts/instagram-posts.component';
import { OrderSummaryComponent } from '../../containers/sections/order-summary/order-summary.component';
import { PricingTableComponent } from '../../containers/sections/pricingTable/pricingTable.component';
import { ProductCardSingleComponent } from '../../containers/sections/product-card-single/product-card-single.component';
import { ProductInfoComponent } from '../../containers/sections/product-info/product-info.component';
import { QuotesComponent } from '../../containers/sections/quotes/quotes.component';
import { ReferenceCardsComponent } from '../../containers/sections/reference-cards/reference-cards.component';
import { ScrollingTextComponent } from '../../containers/sections/scrolling-text/scrolling-text.component';
import { TextWithInitialComponent } from '../../containers/sections/text-with-initial/text-with-initial.component';
import { ThankYouBadgeComponent } from '../../containers/sections/thank-you-badge/thank-you-badge.component';
import { VideoCardsComponent } from '../../containers/sections/video-cards/video-cards.component';
import { Page } from '../../models/collectionTypes/page.model';
import { PageService } from '../../services/strapi/page.service';
import { ComponentType } from '../../shared/enums/componentType.enum';
import { RouteUrls } from '../../shared/enums/route-urls.enum';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  standalone: true,
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
  imports: [
    CommonModule,
    BadgesComponent,
    ProductCardSingleComponent,
    HeaderComponent,
    ImageMarkdownComponent,
    ReferenceCardsComponent,
    VideoCardsComponent,
    FancyboxCardsComponent,
    QuotesComponent,
    InstagramPostsComponent,
    FreeTextComponent,
    ProductInfoComponent,
    ScrollingTextComponent,
    CommentsComponent,
    InstagramPostsOnlyComponent,
    PricingTableComponent,
    TextWithInitialComponent,
    FaqComponent,
    BasketSummaryComponent,
    OrderSummaryComponent,
    AccountDetailsComponent,
    OrderHistoryComponent,
    ThankYouBadgeComponent,
    FeedbackComponent,
    ElfiTeamComponent,
    SpinnerComponent,
  ],
})
export class PageComponent implements OnInit {
  page$: Observable<Page> | undefined;
  componentType = ComponentType;
  strapiUrl: string;
  isLoading$ = new BehaviorSubject<boolean>(false);
  routeUrls = RouteUrls;

  constructor(
    private readonly router: Router,
    private readonly pageService: PageService,
    private readonly configService: AppConfigService,
    private readonly basketItemsService: BasketItemsService,
  ) {}

  isBasketEmpty() {
    return this.basketItemsService.isBasketEmpty();
  }

  ngOnInit() {
    this.strapiUrl = this.configService.getConfigOrThrow().strapiUrl;
    this.page$ = this.pageService
      .getPageByUrl(this.router.url.replace(/\/\d+$/, ''))
      .pipe(
        tap(() => this.isLoading$.next(false)),
        map((response) => {
          if (response.data.pages.data.length != 0) {
            return response.data.pages.data[0].attributes;
          } else {
            this.router.navigate([this.routeUrls.PageNotFound]);
          }
        }),
      );
  }
}
