import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject, catchError, of, takeUntil, tap } from 'rxjs';
import { AppConfigService } from '../../../../config.service';
import { ButtonsSectionComponent } from "../../../../core/buttons-section/buttons-section.component";
import { AccountDetails } from '../../../../models/components/account/accountDetails.model';
import { AuthService, UpdateUserRequest, User } from '../../../../services/auth/auth.service';
import { LoaderService } from '../../../../services/loader.service';
import { NotificationService } from '../../../../services/notification.service';
import { TextInputComponent } from '../../../../shared/components/text-input/text-input.component';
import { ImageUrlDirective } from '../../../../shared/directives/image-url.directive';

@Component({
  standalone: true,
  selector: 'app-accountDetails',
  templateUrl: './accountDetails.component.html',
  styleUrls: ['./accountDetails.component.css'],
  imports: [ButtonsSectionComponent, FontAwesomeModule, CommonModule, ReactiveFormsModule, TextInputComponent, ImageUrlDirective],
})
export class AccountDetailsComponent {
  @Input() accountDetails: AccountDetails;
  iconX = faX;
  faTrash = faTrash;
  showDetails = false;
  showChangePassword = false;
  formGroup!: FormGroup;

  strapiUrl: string;
  user$: Observable<User | null>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly configService: AppConfigService,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly loaderService: LoaderService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  ngOnInit() {
    this.user$ = this.authService.user$;

    this.formGroup = this.formBuilder.group(
      {
        firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        lastName: new FormControl('', [Validators.maxLength(100)]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(256)]),
        street: new FormControl('', [Validators.maxLength(100)]),
        postalCode: new FormControl('', [Validators.maxLength(10)]),
        city: new FormControl('', [Validators.maxLength(100)]),
        oldPassword: new FormControl(''),
        newPassword: new FormControl('', [Validators.minLength(8)]),
      });

    this.user$.pipe(
      takeUntil(this.destroy$))
      .subscribe(user => {
        this.formGroup.patchValue({
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          street: user?.street,
          postalCode: user?.postalCode,
          city: user?.city,
        });
      });
  }

  updateUser() {
    this.loaderService.show();

    const request: UpdateUserRequest = {
      firstName: this.formGroup.get("firstName")?.value,
      lastName: this.formGroup.get("lastName")?.value,
      street: this.formGroup.get("street")?.value,
      postalCode: this.formGroup.get("postalCode")?.value,
      city: this.formGroup.get("city")?.value,
      newPassword: this.formGroup.get("newPassword")?.value,
      oldPassword: this.formGroup.get("oldPassword")?.value,
    };

    this.authService.updateUser(request).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.loaderService.hide();
        this.notificationService.showSuccess("Your account's details have been updated.");
        this.showDetails = false;
        this.clearPasswordFields();
      }),
      catchError(() => {
        this.notificationService.showError("An error occured. Try again.");
        this.clearPasswordFields();
        this.loaderService.hide();
        return of(null);
      })
    ).subscribe();
  };

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  toggleChangePassword() {
    if (this.showChangePassword) {
      this.clearPasswordFields();
    }
    else {
      this.showChangePassword = true;
    }
  }

  clearPasswordFields() {
    this.showChangePassword = false;
    this.formGroup.get("newPassword")?.setValue("");
    this.formGroup.get("oldPassword")?.setValue("");
  }

  deleteAccount() {
    if (!confirm("Are you sure you want to delete your account?")) {
      return;
    }

    this.loaderService.show();

    this.authService.deleteAccount().pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.loaderService.hide();
        this.notificationService.showSuccess("Your account has been deleted.");
        this.router.navigate(["/"]);
      }),
      catchError(() => {
        this.notificationService.showError("An error occured. Try again.");
        this.loaderService.hide();
        return of(null);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
