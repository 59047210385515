export const ElfiTeamSection = `
				... on ComponentSectionsElfiTeam {
          title
          image {
            data {
              attributes {
                url
              }
            }
          }
          elfiMembers {
            name
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
`;
