import { OverlayRef } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { Component, Inject, InjectionToken, Input } from "@angular/core";
import { Fancybox } from "../../../models/collectionTypes/fancybox.model";
import { MarkdownModule } from "ngx-markdown";
import { faLock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

export const FANCYBOX_REF = new InjectionToken<OverlayRef>("FANCYBOX_REF");

@Component({
  standalone: true,
  selector: "app-fancybox",
  templateUrl: "./fancybox.component.html",
  styleUrls: ["./fancybox.component.css"],
  imports: [CommonModule, MarkdownModule, FontAwesomeModule],
})
export class FancyboxComponent {
  readonly faLock = faLock;
  readonly faTimes = faTimes;
  @Input() fancybox: Fancybox;

  constructor(@Inject(FANCYBOX_REF) public overlayRef: OverlayRef) {}

  closePopup() {
    this.overlayRef.dispose();
  }
}
