import { ButtonsSectionData } from '../componentsData/buttonsSection.componentData';
import { InstagramPostData } from '../componentsData/instagramPost.componentData';

export const InstagramPostsSection = `
          ... on ComponentSectionsInstagramPosts {
            text
            posts {
              ${InstagramPostData}
            }
              buttonsSection {
    ${ButtonsSectionData}
  }
            companies {
              data {
                attributes {
                  text
                  companies {
                    name
                    url
                    image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
`;
