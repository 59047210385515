<div class="flex flex-col gap-2">
<div class="flex flex-col text-xs">
  <div class="flex text-gray-500 font-semibold">Quality</div>
  <div class="box">
    @for (qualityOption of qualityOptions; track $index) {
      <label class="flex flex-row items-center gap-2">
        <input
          type="radio"
          [value]="qualityOption"
          [checked]="videoParams.quality === qualityOption.value"
          (change)="handleSelectQuality(qualityOption.value)"
        />
        <div class="flex font-semibold">
          {{ qualityOption.displayName }}
        </div>
        <div class="flex">
          {{ qualityOption.details }}
        </div>
        <div class="flex">{{ qualityOption.displayPrice }}</div>
        <app-tooltip [text]="qualityOption.tooltip"></app-tooltip>
      </label>
    }
  </div>
</div>

<div class="flex flex-col text-xs">
  <div class="flex text-gray-500 font-semibold">Delivery time</div>
  <div class="box">
    @for (deliveryTimeOption of deliveryTimeOptions; track $index) {
      <label class="flex flex-row items-center gap-2">
        <input
          type="radio"
          [value]="deliveryTimeOption"
          [checked]="videoParams.deliveryTime === deliveryTimeOption.value"
          (change)="handleSelectDeliveryTime(deliveryTimeOption.value)"
        />
        <div class="flex font-semibold">
          {{ deliveryTimeOption.displayName }}
        </div>
        <div class="flex">
          {{ deliveryTimeOption.details }}
        </div>
        <div class="flex">{{ deliveryTimeOption.displayPrice }}</div>
      </label>
    }
  </div>
</div>
</div>
