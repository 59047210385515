import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { BasketQuery } from '../../queries/basket.query';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  constructor(private apollo: Apollo) {}

  getBasket(): Observable<ApolloQueryResult<any>> {
    const queryObj = {
      query: BasketQuery,
    };

    return this.apollo.watchQuery(queryObj).valueChanges;
  }
}
