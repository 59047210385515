import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { MarkdownModule } from 'ngx-markdown';
import { AccordionSecondary } from '../../../models/components/accordionSecondary.model';

@Component({
  standalone: true,
  selector: 'app-accordion-secondary',
  templateUrl: './accordion-secondary.component.html',
  styleUrls: ['./accordion-secondary.component.css'],
  imports: [CommonModule, MarkdownModule, FontAwesomeModule],
})
export class AccordionSecondaryComponent {
  @Input() accordion: AccordionSecondary;
  expanded = false;
  chevronRightIcon = faChevronRight;
  chevronDownIcon = faChevronDown;

  constructor() {}

  toggle() {
    this.expanded = !this.expanded;
  }
}
