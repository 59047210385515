<div
  class="flex justify-center w-full my-8"
  [id]="imageMarkdown.elementId"
  [ngStyle]="
    this.imageMarkdown.backgroundImage.data
      ? {
          'background-image':
            'url(' + toImageUrl(this.imageMarkdown.backgroundImage.data) + ')',
          'background-size': 'cover',
          color: 'white',
        }
      : {}
  "
>
  <div class="content relative">
    @if (isMobileView()) {
      <div class="flex flex-col items-start gap-4 my-auto mt-8">
        <markdown class="title">{{ imageMarkdown.title }}</markdown>
        <markdown class="description">{{ imageMarkdown.description }}</markdown>
        <app-buttons-section
          [buttonsSection]="imageMarkdown.buttonsSection"
        ></app-buttons-section>
        <ng-container [ngTemplateOutlet]="image" />
      </div>
    } @else {
      @if (!imageMarkdown.imageOnRight) {
        <ng-container [ngTemplateOutlet]="imageAbsolute" />
      }
      <div class="flex flex-col items-start gap-4 my-auto max-w-[60%]">
        <markdown class="title">{{ imageMarkdown.title }}</markdown>
        <markdown class="description">{{ imageMarkdown.description }}</markdown>
        <app-buttons-section
          [buttonsSection]="imageMarkdown.buttonsSection"
        ></app-buttons-section>
      </div>
      @if (imageMarkdown.imageOnRight) {
        <ng-container [ngTemplateOutlet]="imageAbsolute" />
      }
    }
  </div>
</div>

<ng-template #image>
  <img appImageUrl [iconData]="imageMarkdown.image.data" class="image" />
</ng-template>

<ng-template #imageAbsolute>
  <img
    appImageUrl
    [iconData]="imageMarkdown.image.data"
    class="image absolute bottom-0 right-0"
  />
</ng-template>
