<div class="flex gap-8 w-full mt-4">
  <ng-container [ngTemplateOutlet]="textInstance" />
  <ng-container [ngTemplateOutlet]="textInstance" />
</div>

<ng-template #textInstance>
  <div class="text-wrapper gap-8">
    @for (item of textArray; track $index) {
      <div class="break-keep font-bold">{{ item.normalText }}</div>
      <div class="break-keep font-bold stroke">{{ item.outlineText }}</div>
    }
  </div>
</ng-template>
