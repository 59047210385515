export enum ComponentType {
  Badges = 'ComponentSectionsBadges',
  ProductCardSingle = 'ComponentSectionsProductCardSingle',
  ImageMarkdown = 'ComponentSectionsImageMarkdown',
  ReferenceCards = 'ComponentSectionsReferenceCards',
  VideoCards = 'ComponentSectionsVideoCards',
  FancyboxCards = 'ComponentSectionsFancyboxCards',
  Reviews = 'ComponentSectionsReviews',
  Quotes = 'ComponentSectionsQuotes',
  InstagramPosts = 'ComponentSectionsInstagramPosts',
  InstagramPostsOnly = 'ComponentSectionsInstagramPostsOnly',
  FreeText = 'ComponentSectionsFreeText',
  ProductInfo = 'ComponentSectionsProductInfo',
  ScrollingText = 'ComponentSectionsScrollingText',
  Comments = 'ComponentSectionsComments',
  PricingTable = 'ComponentSectionsPricingTable',
  TextWithInitial = 'ComponentSectionsTextWithInitial',
  FAQ = 'ComponentSectionsFaq',
  BasketSummary = 'ComponentBasketBasketSummary',
  OrderSummary = 'ComponentOrderOrderSummary',
  AccountDetails = 'ComponentAccountAccountDetails',
  OrderHistory = 'ComponentAccountOrderHistory',
  ThankYouBadge = 'ComponentSectionsThankYouBadge',
  Feedback = 'ComponentSectionsFeedback',
  ElfiTeam = 'ComponentSectionsElfiTeam',
}
