<div class="absolute inset-0 z-50">
  <div class="wrapper" (click)="closePopup()">
    <div class="modal" (click)="$event.stopPropagation()">
      <div class="relative flex h-1/2">
        <fa-icon
          [icon]="faTimes"
          size="xl"
          class="close-button"
          (click)="closePopup()"
        />
        @if (this.videoPlaying) {
          <video
            class="player"
            playsinline
            autoplay="autoplay"
            controls
            loop
            [src]="productCardSingle.productCard.data.attributes.videoUrl"
            type="video/mp4"
          ></video>
        } @else {
          <img
            class="top-image"
            appImageUrl
            [iconData]="
              productCardSingle.productCard.data.attributes.image.data
            "
          />
          <div class="absolute h-full flex flex-col justify-end gap-2 p-8 pb-0">
            <div class="flex flex-row gap-2">
              @for (
                tag of productCardSingle.productCard.data.attributes.tags;
                track $index
              ) {
                <div class="chip">
                  <div>{{ tag.label }}</div>
                </div>
              }
            </div>
            <markdown class="text">{{
              productCardSingle.productCard.data.attributes.text
            }}</markdown>
          </div>
          <div class="absolute h-full w-full flex items-center justify-center">
            <img
              (click)="startVideo()"
              class="w-10 cursor-pointer"
              appImageUrl
              [iconData]="
                productCardSingle.productCard.data.attributes.playIcon.data
              "
            />
          </div>
        }
      </div>
      <div>
        <div class="flex flex-col w-full p-4 gap-2 sm:flex-row sm:p-8">
          <div class="flex flex-col gap-4 w-full sm:w-3/5">
            @if (productCardSingle.productCard.data.attributes.modalLabels) {
              <div class="flex flex-row gap-2 flex-wrap">
                @for (
                  label of productCardSingle.productCard.data.attributes
                    .modalLabels;
                  track $index
                ) {
                  <app-text-and-icon [textAndIcon]="label"></app-text-and-icon>
                }
              </div>
            }
            @if (
              productCardSingle.productCard.data.attributes.informationTabs
            ) {
              <app-information-tabs
                [informationTabs]="
                  productCardSingle.productCard.data.attributes.informationTabs
                "
              ></app-information-tabs>
            }
            <div class="flex flex-col gap-2 mt-auto">
              <div class="flex flex-row">
                @for (
                  productOption of productCardSingle.productCard.data.attributes
                    .productOptions;
                  track $index
                ) {
                  @if (selectedOption == productOption) {
                    <app-button
                      class="submit w-full"
                      [buttonLink]="productOption.button"
                    ></app-button>
                  }
                }
              </div>
            </div>
          </div>
          <markdown class="additionalInfo">{{
            productCardSingle.productCard.data.attributes.additionalInfo
          }}</markdown>
        </div>
      </div>
    </div>
  </div>
</div>
