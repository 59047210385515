<div class="minHeightWrapper">
  @if (this.page$ | async; as page) {
    <div class="flex flex-col items-center overflow-x-hidden *:w-full">
      <app-header [header]="page.header"></app-header>
      @for (component of page.Sections; track $index) {
        @switch (component.__typename) {
          @case (componentType.Badges) {
            <app-badges [badges]="$any(component)"></app-badges>
          }
          @case (componentType.ProductCardSingle) {
            <app-product-card-single
              [productCardSingle]="$any(component)"
            ></app-product-card-single>
          }
          @case (componentType.ImageMarkdown) {
            <app-image-markdown
              [imageMarkdown]="$any(component)"
            ></app-image-markdown>
          }
          @case (componentType.ReferenceCards) {
            <app-reference-cards
              [referenceCards]="$any(component)"
            ></app-reference-cards>
          }
          @case (componentType.VideoCards) {
            <app-video-cards [videoCards]="$any(component)"></app-video-cards>
          }
          @case (componentType.FancyboxCards) {
            <app-fancybox-cards
              [fancyboxCards]="$any(component)"
            ></app-fancybox-cards>
          }
          @case (componentType.Quotes) {
            <app-quotes [quotes]="$any(component)"></app-quotes>
          }
          @case (componentType.InstagramPosts) {
            <app-instagram-posts
              [instagramPosts]="$any(component)"
            ></app-instagram-posts>
          }
          @case (componentType.InstagramPostsOnly) {
            <app-instagram-posts-only
              [instagramPostsOnly]="$any(component)"
            ></app-instagram-posts-only>
          }
          @case (componentType.FreeText) {
            <app-freeText [freeText]="$any(component)"></app-freeText>
          }
          @case (componentType.ProductInfo) {
            <app-product-info [productInfo]="$any(component)"></app-product-info>
          }
          @case (componentType.ScrollingText) {
            <app-scrolling-text
              [scrollingText]="$any(component)"
            ></app-scrolling-text>
          }
          @case (componentType.Comments) {
            <app-comments [comments]="$any(component)"></app-comments>
          }
          @case (componentType.PricingTable) {
            <app-pricingTable
              [pricingTable]="$any(component)"
            ></app-pricingTable>
          }
          @case (componentType.TextWithInitial) {
            <app-text-with-initial
              [textWithInitial]="$any(component)"
            ></app-text-with-initial>
          }
          @case (componentType.FAQ) {
            <app-faq [faq]="$any(component)"></app-faq>
          }
          @case (componentType.BasketSummary) {
            <app-basket-summary
              [basketSummary]="$any(component)"
            ></app-basket-summary>
          }
          @case (componentType.OrderSummary) {
            <app-order-summary
              [orderSummary]="$any(component)"
            ></app-order-summary>
          }
          @case (componentType.AccountDetails) {
            <app-accountDetails
              [accountDetails]="$any(component)"
            ></app-accountDetails>
          }
          @case (componentType.OrderHistory) {
            <app-orderHistory
              [orderHistory]="$any(component)"
            ></app-orderHistory>
          }
          @case (componentType.ThankYouBadge) {
            <app-thank-you-badge
              [thankYouBadge]="$any(component)"
            ></app-thank-you-badge>
          }
          @case (componentType.Feedback) {
            <app-feedback [feedback]="$any(component)"></app-feedback>
          }
          @case (componentType.ElfiTeam) {
            <app-elfi-team [elfiTeam]="$any(component)"></app-elfi-team>
          }
        }
      }
    </div>
  } @else {
    <app-spinner></app-spinner>
  }
</div>
