import { ButtonsSectionData } from "../componentsData/buttonsSection.componentData";

export const FAQSection = `
          ... on ComponentSectionsFaq {
            buttonsSection {
              ${ButtonsSectionData}
            }
            questions {
              title
              icon {
                data {
                  attributes {
                    url
                  }
                }
              }
              iconExpanded {
                data {
                  attributes {
                    url
                  }
                }
              }
              text
              questions {
                title
                text
              }
            }
          }
`;