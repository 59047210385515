export enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  text = "text",
  navbar = "navbar",
  videoPrimary = "videoPrimary",
  videoSecondary = "videoSecondary",
  scrollToElementPrimary = "scrollToElementPrimary",
  scrollToElementSecondary = "scrollToElementSecondary",
  accordion = "accordion",
  basket = "basket",
}
