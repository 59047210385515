import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { AppConfigService } from '../../config.service';
import { BASKET_MODAL_REF, BasketPopupComponent } from '../../containers/basket/basket-popup/basket-popup.component';
import { VIDEO_MODAL_REF, VideoModalComponent } from '../../containers/modals/video-modal/video-modal.component';
import { ButtonLink } from '../../models/components/button.model';
import { ModalService } from '../../services/modal.service';
import { ImageUrlDirective } from '../../shared/directives/image-url.directive';
import { ButtonType } from '../../shared/enums/buttonType.enum';

@Component({
  standalone: true,
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
  imports: [RouterModule, MarkdownModule, ImageUrlDirective],
})
export class ButtonComponent {
  @Input() buttonLink: ButtonLink;
  buttonType = ButtonType;

  accordionShow = false;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService,
    private readonly modalService: ModalService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  openVideoModal() {
    const ref = this.modalService.openModal<VideoModalComponent>(
      VideoModalComponent,
      VIDEO_MODAL_REF,
    )

    ref.setInput("videoUrl", this.buttonLink.link.data.attributes.url)
  }

  openBasket() {
    this.modalService.openModal<BasketPopupComponent>(
      BasketPopupComponent,
      BASKET_MODAL_REF,
    );
  }

  scrollToElement(elementId: string) {
    if (!elementId) {
      return;
    }

    document.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  toggleAccordion() {
    this.accordionShow = !this.accordionShow;
  }
}
