<div class="absolute inset-0 z-50">
  <div class="background" (click)="closePopup()">
    <div class="post" (click)="$event.stopPropagation()">
      <div class="flex-shrink-1">
        <img
          appImageUrl
          [iconData]="instagramPost.image.data"
          class="main-image"
        />
      </div>
      <div class="right-panel-wrapper">
        <div class="right-panel">
          <div class="flex flex-col gap-8">
            <div class="flex items-center justify-between">
              <a
                class="flex flex-row items-center gap-3"
                [href]="instagramPost.profileUrl"
                target="_blank"
              >
                <img
                  appImageUrl
                  [iconData]="instagramPost.profilePhoto.data"
                  class="rounded-full w-8"
                />
                <div class="flex gap-1">
                  <p class="username">{{ instagramPost.username }}</p>
                  <span class="dot">•</span>
                  <span class="follow">Follow</span>
                </div>
              </a>
              <fa-icon
                [icon]="timesIcon"
                class="close-button"
                (click)="closePopup()"
              />
            </div>

            <div class="flex items-start gap-2">
              <img
                appImageUrl
                [iconData]="instagramPost.profilePhoto.data"
                class="rounded-full w-8"
              />
              <markdown class="description">
                <span class="username">{{ instagramPost.username }}</span>
                {{ instagramPost.description }}
              </markdown>
            </div>
          </div>
          <div>
            <markdown class="like-comment">{{
              instagramPost.likesAndComments
            }}</markdown>
          </div>
        </div>
      </div>
    </div>
    <div (click)="$event.stopPropagation()">
      <button class="change-button left" (click)="nextCard()">
        <fa-icon
          [icon]="chevronLeftIcon"
          class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </button>
      <button class="change-button right" (click)="previousCard()">
        <fa-icon
          [icon]="chevronRightIcon"
          class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </button>
    </div>
  </div>
</div>
