import { Component, Input } from '@angular/core';
import { FancyboxCards } from '../../../models/components/sections/fancyboxCards.model';
import { FancyboxCardComponent } from "../../elements/fancybox-card/fancybox-card.component";
import { FancyboxCardsType } from '../../../shared/enums/fancyboxCardsType.enum';

@Component({
  standalone: true,
  selector: 'app-fancybox-cards',
  templateUrl: './fancybox-cards.component.html',
  styleUrls: ['./fancybox-cards.component.css'],
  imports: [FancyboxCardComponent],
})
export class FancyboxCardsComponent {
  @Input() fancyboxCards: FancyboxCards;
  fancyboxCardsType = FancyboxCardsType;
}
