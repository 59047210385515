<div class="wrapper" (click)="openModal()">
  <div class="flex flex-row items-center gap-2 p-4">
    <img
      appImageUrl
      [iconData]="instagramPost.profilePhoto.data"
      class="rounded-full w-10"
    />
    <div class="text-lg">{{ instagramPost.username }}</div>
  </div>
  <img class="h-full" appImageUrl [iconData]="instagramPost.image.data" />
</div>
