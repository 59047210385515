export const FancyboxCardsSection = `
... on ComponentSectionsFancyboxCards {
    type
    cards {
      icon {
        data {
          attributes {
            url
          }
        }
      }
      fancybox {
        data {
          attributes {
            title
            content
          }
        }
      }
      url
      title
      description
      tooltip
    }
  }
`;
