import { ButtonsSectionData } from '../../componentsData/buttonsSection.componentData';

export const AccountDetailsSection = `
          ... on ComponentAccountAccountDetails {
            title
            editLabel
            editIcon {
              data {
                attributes {
                  url
                }
              }
            }
            buttonsSection {
              ${ButtonsSectionData}
            }
          }
`;
