import { ButtonsSectionData } from '../componentsData/buttonsSection.componentData';

export const CommentsSection = `
          ... on ComponentSectionsComments {
            title
            imageTop {
              data {
                attributes {
                  url
                }
              }
            }
            imageBottom {
              data {
                attributes {
                  url
                }
              }
            }
            imageLeft {
              data {
                attributes {
                  url
                }
              }
            }
            imageRight {
              data {
                attributes {
                  url
                }
              }
            }
              buttonsSection {
    ${ButtonsSectionData}
  }
            comments {
              data {
                attributes {
                  title
                  comments {
                    text
                    image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
`;
