import { ButtonsSectionData } from '../componentsData/buttonsSection.componentData';

export const ImageMarkdownSection = `
... on ComponentSectionsImageMarkdown {
  elementId
  title
  description
  image {
    data {
      attributes {
        url
      }
    }
  }
  backgroundImage {
    data {
      attributes {
        url
      }
    }
  }
  imageOnRight
  buttonsSection {
    ${ButtonsSectionData}
  }
}
`;
