import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faX } from '@fortawesome/free-solid-svg-icons';
import { catchError, of, tap } from 'rxjs';
import { AppConfigService } from '../../../config.service';
import { BasketSummary } from '../../../models/components/basket/basketSummary.model';
import { UsageType } from '../../../models/discountCode/enums/usageType.enum';
import { DiscountCodesService } from '../../../services/discount-codes.service';
import { LoaderService } from '../../../services/loader.service';
import { NotificationService } from '../../../services/notification.service';
import { PriceListService } from '../../../services/price-lists.service';
import { CodeInputComponent } from "../../../shared/components/code-input/code-input.component";
import { TooltipComponent } from '../../../shared/components/tooltip/tooltip.component';
import { ControlType } from '../../../shared/enums/controlType.enum';
import { FancyboxCardsType } from '../../../shared/enums/fancyboxCardsType.enum';
import { FancyboxCardComponent } from '../../elements/fancybox-card/fancybox-card.component';
import { PromoCodeInputComponent } from '../../elements/promo-code-input/promo-code-input.component';
import { FancyboxComponent } from '../../modals/fancybox/fancybox.component';
import { FancyboxCardsComponent } from '../../sections/fancybox-cards/fancybox-cards.component';
import { ProductType } from '../../video-generator-form/models/video-form-config';
import {
  DeliveryTimeOption,
  QualityOption,
} from '../../video-generator-form/models/video-params.model';
import { VideoParametersShortComponent } from '../components/video-parameters-short/video-parameters-short.component';
import { BasketItem } from '../models/basketItem.model';
import { BasketItemsService } from '../services/basketItems.service';

@Component({
  selector: 'app-basket-summary',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    PromoCodeInputComponent,
    FontAwesomeModule,
    FancyboxCardsComponent,
    FancyboxCardComponent,
    TooltipComponent,
    VideoParametersShortComponent,
    FancyboxComponent,
    CodeInputComponent
],
  templateUrl: './basket-summary.component.html',
  styleUrl: './basket-summary.component.scss',
})
export class BasketSummaryComponent {
  readonly faChevronRight = faChevronRight;
  readonly fancyboxCardsType = FancyboxCardsType;
  @Input() basketSummary: BasketSummary;
  strapiUrl: string;
  iconTrash = faTrashCan;
  controlType = ControlType;
  productType = ProductType;
  iconX = faX;

  basketItems: BasketItem[] = [];

  qualityOptions: QualityOption[];
  deliveryTimeOptions: DeliveryTimeOption[];

  constructor(
    private basketItemsService: BasketItemsService,
    private readonly configService: AppConfigService,
    private readonly priceListService: PriceListService,
    private readonly discountCodeService: DiscountCodesService,
    private readonly notificationService: NotificationService,
    private readonly loaderService: LoaderService,
  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  ngOnInit(): void {
    this.initItems();
    this.qualityOptions = this.priceListService.getQualityOptions();
    this.deliveryTimeOptions = this.priceListService.getDeliveryTimeOptions();
  }

  initItems() {
    this.basketItems = this.basketItemsService.items;
  }

  get total() {
    return this.basketItemsService.total;
  }

  removeItem(item: any) {
    this.basketItemsService.remove(item);
    this.initItems();
  }

  updateItems() {
    this.basketItemsService.syncItems();
  }

  applyDiscountCode(item: BasketItem, code: string) {
    if(!code){
      this.notificationService.showError('Please enter a code.');
      return;
    }

    this.loaderService.show();
    this.discountCodeService
      .getDiscountCode(code)
      .pipe(
        tap((response) => {
          this.loaderService.hide();
          if(this.basketItems.some((item) => item.discountCode?.code === code && item.discountCode?.usageType == UsageType.SingleUse)) {
            this.notificationService.showError('This code can be used only once.');
            return;
          }

          item.discountCode = {
            id: response.id,
            code: response.code,
            discountAmountValue: response.discountAmountValue,
            discountPercentageValue: response.discountPercentageValue,
            discountType: response.discountType,
            usageType: response.usageType
          };

          this.updateItems();
        }),
        catchError(() => {
          this.loaderService.hide();
          this.notificationService.showError('Entered code is invalid.');
          return of(null);
        }),
      )
      .subscribe();
  }

  removeDiscountCode(item: BasketItem) {
    item.discountCode = undefined;
    this.updateItems();
  }

  get preDiscountSum() {
    return this.basketItems.reduce((total, item) => total + (item.pricePreDiscount), 0);
  }

  get discountSum() {
    return this.basketItems.reduce((total, item) => total + (item.pricePreDiscount - item.price), 0);
  }
}
