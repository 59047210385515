import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { AppConfigService } from '../../../config.service';
import { FancyboxCard } from '../../../models/components/fancyboxCard.model';
import { ModalService } from '../../../services/modal.service';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';
import { FancyboxCardsType } from '../../../shared/enums/fancyboxCardsType.enum';
import {
  FANCYBOX_REF,
  FancyboxComponent,
} from '../../modals/fancybox/fancybox.component';

@Component({
  standalone: true,
  selector: 'app-fancybox-card',
  templateUrl: './fancybox-card.component.html',
  styleUrls: ['./fancybox-card.component.css'],
  imports: [MarkdownModule, ImageUrlDirective],
})
export class FancyboxCardComponent {
  @Input() fancyboxCard: FancyboxCard;
  @Input() type: FancyboxCardsType = FancyboxCardsType.big;
  fancyboxCardsType = FancyboxCardsType;
  strapiUrl: string;

  constructor(
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  handleClick() {
    if (this.fancyboxCard.fancybox.data == null) {
      if (this.fancyboxCard.url) {
        this.router.navigateByUrl(this.fancyboxCard.url);
      }
      return;
    }

    const ref = this.modalService.openModal<FancyboxComponent>(
      FancyboxComponent,
      FANCYBOX_REF,
    );

    ref.setInput('fancybox', this.fancyboxCard.fancybox.data.attributes);
  }
}
