import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import PageQuery from '../../queries/page.query';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private apollo: Apollo) { }

  getPageByUrl(pageUrl: string): Observable<ApolloQueryResult<any>> {
    const queryObj = {
      query: PageQuery,
      variables: { url: pageUrl },
    };

    return this.apollo.watchQuery(queryObj).valueChanges;
  }
}
