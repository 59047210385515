import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHeart, faSearch } from '@fortawesome/free-solid-svg-icons';
import { MarkdownModule } from 'ngx-markdown';
import { AppConfigService } from '../../../config.service';
import { ButtonsSectionComponent } from '../../../core/buttons-section/buttons-section.component';
import { ProductCardSingle } from '../../../models/components/sections/productcardsingle.model';
import { ModalService } from '../../../services/modal.service';
import { ProjectService } from '../../../services/strapi/project.service';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';
import { TextAndIconComponent } from '../../elements/text-and-icon/text-and-icon.component';
import {
  PRODUCT_CARD_PRIMARY_REF,
  ProductCardPrimaryModalComponent,
} from '../../modals/product-card-primary-modal/product-card-primary-modal.component';

@Component({
  standalone: true,
  selector: 'app-product-card-single',
  templateUrl: './product-card-single.component.html',
  styleUrls: ['./product-card-single.component.css'],
  imports: [
    MarkdownModule,
    CommonModule,
    ButtonsSectionComponent,
    TextAndIconComponent,
    FontAwesomeModule,
    ImageUrlDirective
  ],
})
export class ProductCardSingleComponent implements OnInit {
  @Input() productCardSingle: ProductCardSingle;

  readonly faHeart = faHeart;
  readonly faSearch = faSearch;
  counter: number = 0;
  isProjectLiked: boolean = false;

  strapiUrl: string;

  constructor(
    private readonly modalService: ModalService,
    private readonly projectService: ProjectService,
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  ngOnInit(): void {
    this.counter =
      this.productCardSingle.productCard.data.attributes.likeCounter.data.attributes.counter;
  }

  openProductModal() {
    const ref = this.modalService.openModal<ProductCardPrimaryModalComponent>(
      ProductCardPrimaryModalComponent,
      PRODUCT_CARD_PRIMARY_REF,
    );

    ref.setInput('productCardSingle', this.productCardSingle);
  }

  likeProject() {
    this.projectService.updateProjectLikeCounter(
      this.productCardSingle.productCard.data.attributes.likeCounter.data.id,
      this.counter + 1,
    );

    this.counter++;
    this.isProjectLiked = true;
  }
}
