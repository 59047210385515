export const BasketSummarySection = `
... on ComponentBasketBasketSummary {
    id
    cards {
      icon {
        data {
          attributes {
            url
          }
        }
      }
      fancybox {
        data {
          attributes {
            title
            content
          }
        }
      }
      url
      title
      description
      tooltip
    }
}
`;
