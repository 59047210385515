import { Component, Input } from '@angular/core';
import { Feedback } from '../../../models/components/sections/feedback.model';
import { FeedbackListComponent } from '../../elements/feedback-list/feedback-list.component';

@Component({
  standalone: true,
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  imports: [FeedbackListComponent],
})
export class FeedbackComponent {
  @Input() feedback: Feedback;
}
