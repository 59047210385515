<div class="reference-cards-wrapper">
  @for (card of this.referenceCards.cards; track $index) {
    <div class="reference-card" (click)="scrollToElement(card.elementId)">
      <div
        class="flex flex-col items-center hover:text-red-600 cursor-pointer gap-4"
      >
        <div class="bg-[#e6e6e6] rounded-full h-16 w-16 relative">
          <img
            appImageUrl
            [iconData]="card.icon.data"
            class="h-8 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <markdown class="title">{{ card.title }}</markdown>
      </div>
      <markdown class="description">{{ card.description }}</markdown>
    </div>
  }
</div>
