import { Component, Input } from '@angular/core';
import { PromoCodeInput } from '../../../models/components/basket/promoCodeInput.model';

@Component({
  standalone: true,
  selector: 'app-promo-code-input',
  templateUrl: './promo-code-input.component.html',
  styleUrls: ['./promo-code-input.component.css'],
})
export class PromoCodeInputComponent {
  @Input() promoCodeInput: PromoCodeInput;
  showInput: boolean = false;

  toggleInput() {
    this.showInput = !this.showInput;
  }

  applyPromoCode() {
    //todo implement logic
  }
}
