<div class="flex flex-row p-4 py-8 w-full">
  <ng-container [ngTemplateOutlet]="badgesInstance" />
  <ng-container [ngTemplateOutlet]="badgesInstance" />
  <ng-container [ngTemplateOutlet]="badgesInstance" />
  <ng-container [ngTemplateOutlet]="badgesInstance" />
</div>

<ng-template #badgesInstance>
  <div class="badge-wrapper">
    @for (badge of badges.badges; track $index) {
      <div class="badge">
        <img appImageUrl [iconData]="badge.icon.data" class="h-6" />
        <markdown class="pt-4">{{ badge.text }}</markdown>
      </div>
    }
  </div>
</ng-template>
