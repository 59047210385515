<div class="absolute inset-0 z-50">
  <div
    class="fixed backdrop-brightness-50 h-full w-full flex place-content-end"
    (click)="closePopup()"
  >
    <div
      [@openCloseBasket]
      class="flex flex-col bg-white gap-2 p-4 w-96"
      (click)="$event.stopPropagation()"
    >
      <div class="flex flex-col gap-2 items-center justify-between h-full">
        @if (basket$ | async; as basket) {
          <div class="flex flex-col gap-4 w-full">
            <div class="flex flex-col items-end">
              <button
                class="shadow-md p-2 rounded-full w-10 h-10 opacity-60 hover:opacity-100"
                (click)="closePopup()"
              >
                <fa-icon [icon]="iconX" class="text-md"></fa-icon>
              </button>
            </div>
            @for (item of basketItems; track $index) {
              <div class="flex flex-row items-center justify-between gap-4">
                <img [src]="item.thumbnailUrl" class="h-12 rounded-lg" />
                <div class="flex flex-col">
                  <div>{{ productType[item.type] }}</div>
                  <div class="text-gray-400">{{ item.name }}</div>
                </div>
                <div class="">{{ item.price | currency }}</div>
                <button (click)="removeItem(item)">
                  <fa-icon [icon]="iconTrash" class="text-md"></fa-icon>
                </button>
              </div>
            }
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col items-end">
              <div class="font-bold text-4xl">
                {{ this.basketTotal | currency }}
              </div>
            </div>
            @if (!this.isBasketEmpty()) {
              <app-button [buttonLink]="basket.button"></app-button>
            }
            <div class="flex flex-col items-center">
              <markdown>{{ basket.text }}</markdown>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
