import { CommonModule } from "@angular/common";
import { Component, ElementRef, Input, viewChild } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { ButtonComponent } from "../../../core/button/button.component";
import { ButtonsSectionComponent } from "../../../core/buttons-section/buttons-section.component";
import { InstagramPosts } from "../../../models/components/sections/instagramPosts.model";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";
import { InstagramPostComponent } from "../../elements/instagram-post/instagram-post.component";

@Component({
  standalone: true,
  selector: 'app-instagram-posts',
  templateUrl: './instagram-posts.component.html',
  styleUrls: ['./instagram-posts.component.css'],
  imports: [
    CommonModule,
    MarkdownModule,
    InstagramPostComponent,
    ButtonComponent,
    FontAwesomeModule,
    ButtonsSectionComponent,
    ImageUrlDirective
  ],
})
export class InstagramPostsComponent {
  @Input() instagramPosts: InstagramPosts;
  postsWrapper = viewChild<ElementRef>("postsWrapper");
  strapiUrl: string;

  readonly chevronLeftIcon = faChevronLeft;
  readonly chevronRightIcon = faChevronRight;

  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  next() {
    this.postsWrapper()?.nativeElement.scrollTo(
      this.postsWrapper()?.nativeElement.scrollLeft + 300,
      0
    );
  }

  previous() {
    this.postsWrapper()?.nativeElement.scrollTo(
      this.postsWrapper()?.nativeElement.scrollLeft - 300,
      0
    );
  }
}
