import { Component, Input } from '@angular/core';
import { PricingTable } from '../../../models/components/sections/pricingTable.model';
import { MarkdownModule } from 'ngx-markdown';
import { ButtonsSectionComponent } from "../../../core/buttons-section/buttons-section.component";

@Component({
  standalone: true,
  selector: 'app-pricingTable',
  templateUrl: './pricingTable.component.html',
  styleUrls: ['./pricingTable.component.css'],
  imports: [MarkdownModule, ButtonsSectionComponent],
})
export class PricingTableComponent {
  @Input() pricingTable: PricingTable;
}
