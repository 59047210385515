export const ThankYouBadgeSection = `
          ... on ComponentSectionsThankYouBadge {
            text
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
`;
