export const BadgesSection = `
... on ComponentSectionsBadges {
  badges {
    icon {
      data {
        attributes {
          url
        }
      }
    }
    text
  }
}
`;
