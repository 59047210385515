import { gql } from 'apollo-angular';
import { ButtonLinkData } from './componentsData/buttonLink.componentData';

export const BasketQuery = gql`
query Basket {
  basket {
    data {
      attributes {
        text
        promoCodeInput {
          label
          placeholder
          buttonLabel
        }
        button {
          ${ButtonLinkData}
        }
      }
    }
  }
}
`;
