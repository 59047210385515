@switch (type) {
  @case (fancyboxCardsType.small) {
    <div
      class="flex flex-row items-center cursor-pointer gap-4"
      [title]="fancyboxCard.tooltip"
    >
      <div class="bg-[#e6e6e6] rounded-full h-8 w-8 relative">
        <img
          appImageUrl
          [iconData]="fancyboxCard.icon.data"
          class="h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div class="text-sm font-bold">{{ fancyboxCard.title }}</div>
    </div>
  }
  @case (fancyboxCardsType.medium) {
    <div
      class="flex flex-col items-center text-center gap-4 cursor-pointer"
      (click)="handleClick()"
    >
      <div class="bg-[#e6e6e6] rounded-full h-8 w-8 relative">
        <img
          appImageUrl
          [iconData]="fancyboxCard.icon.data"
          class="h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div class="flex flex-col gap-1">
        <markdown class="title hover:text-red-600">{{
          fancyboxCard.title
        }}</markdown>
        <markdown class="description">{{ fancyboxCard.description }}</markdown>
      </div>
    </div>
  }
  @case (fancyboxCardsType.big) {
    <div class="flex flex-col items-center text-center">
      <div
        class="flex flex-col items-center hover:text-red-600 cursor-pointer gap-4"
        (click)="handleClick()"
      >
        <div class="bg-[#e6e6e6] rounded-full h-16 w-16 relative">
          <img
            appImageUrl
            [iconData]="fancyboxCard.icon.data"
            class="h-8 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <markdown class="title">{{ fancyboxCard.title }}</markdown>
      </div>
      <markdown class="description">{{ fancyboxCard.description }}</markdown>
    </div>
  }
}
