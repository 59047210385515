import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../../config.service';
import { ButtonComponent } from "../../../core/button/button.component";
import { ButtonsSectionComponent } from "../../../core/buttons-section/buttons-section.component";
import { Commnets } from '../../../models/components/sections/comments.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';

@Component({
  standalone: true,
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css'],
  imports: [ButtonComponent, ButtonsSectionComponent, ImageUrlDirective],
})
export class CommentsComponent {
  @Input() comments: Commnets;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }
}
