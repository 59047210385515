<div
  class="my-8"
  [ngStyle]="
    this.productInfo.bgImage.data
      ? {
          'background-image':
            'url(' + toImageUrl(this.productInfo.bgImage.data) + ')',
          'background-size': 'cover',
          color: 'white',
        }
      : {}
  "
>
  <div class="product-info-wrapper">
    <ng-container
      *ngIf="!productInfo.imageOnRight || isNarrowerView()"
      [ngTemplateOutlet]="video"
    />
    <div class="flex flex-col justify-center gap-4 p-4 grow object-fill">
      <markdown class="max-w-[500px] mt-4">{{ productInfo.text }}</markdown>
      <app-buttons-section
        [buttonsSection]="productInfo.buttonsSection"
      ></app-buttons-section>
    </div>
    <ng-container
      *ngIf="productInfo.imageOnRight && !isNarrowerView()"
      [ngTemplateOutlet]="video"
    />
  </div>
</div>

<ng-template #video>
  <div
    class="relative flex justify-center items-center"
    [ngClass]="!isNarrowerView() ? 'w-[60vw]' : 'w-full'"
  >
    <iframe
      [src]="productInfo.imageUrl | safe"
      class="w-full object-cover aspect-video"
      allow="fullscreen;"
    ></iframe>
  </div>
</ng-template>