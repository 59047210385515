import { Component, Input } from "@angular/core";
import { AppConfigService } from "../../../config.service";
import { InstagramPost } from "../../../models/collectionTypes/instagramPost.model";
import { InstagramPosts } from "../../../models/components/sections/instagramPosts.model";
import { ModalService } from "../../../services/modal.service";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";
import {
  INSTAGRAM_POST_REF,
  InstagramPostModalComponent,
} from "../../modals/instagram-post-modal/instagram-post-modal.component";

@Component({
  standalone: true,
  selector: 'app-instagram-post',
  templateUrl: './instagram-post.component.html',
  styleUrls: ['./instagram-post.component.css'],
  imports: [ImageUrlDirective]
})
export class InstagramPostComponent {
  @Input() instagramPost: InstagramPost;
  @Input() allPosts: InstagramPosts;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService,
    private readonly modalService: ModalService
  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  openModal() {
    const ref = this.modalService.openModal<InstagramPostModalComponent>(
      InstagramPostModalComponent,
      INSTAGRAM_POST_REF
    );

    ref.setInput("instagramPost", this.instagramPost);
    ref.setInput(
      "allPosts",
      this.allPosts.posts.data.map((x) => x.attributes)
    );
  }
}
