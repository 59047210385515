import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VideoQuality, VideoDeliveryTime, VideoParams, DeliveryTimeOption, QualityOption } from '../../../video-generator-form/models/video-params.model';
import { TooltipComponent } from "../../../../shared/components/tooltip/tooltip.component";
import { PriceListService } from '../../../../services/price-lists.service';

@Component({
  selector: 'app-video-parameters-short',
  standalone: true,
  imports: [TooltipComponent],
  templateUrl: './video-parameters-short.component.html',
  styleUrl: './video-parameters-short.component.scss'
})
export class VideoParametersShortComponent {
  @Input() videoParams: VideoParams;
  @Output() onChange = new EventEmitter;

  readonly qualities = VideoQuality;
  readonly deliveryTimes = VideoDeliveryTime;

  qualityOptions: QualityOption[];
  deliveryTimeOptions: DeliveryTimeOption[];

  constructor(private readonly priceListService: PriceListService) { }

  ngOnInit() {
    this.qualityOptions = this.priceListService.getQualityOptions();
    this.deliveryTimeOptions = this.priceListService.getDeliveryTimeOptions();
  }

  handleSelectQuality(option: VideoQuality) {
    this.videoParams.quality = option;
    this.onChange.emit();
  }

  handleSelectDeliveryTime(option: VideoDeliveryTime) {
    this.videoParams.deliveryTime = option;
    this.onChange.emit();
  }
}
