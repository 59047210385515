<div class="flex flex-col items-start gap-2 cursor-pointer">
  <div
    class="flex flex-row items-center w-full justify-start gap-2"
    (click)="toggle()"
  >
    <fa-icon [icon]="expanded ? chevronDownIcon : chevronRightIcon" class="w-4"></fa-icon>
    <div
      class="text-2xl hover:font-bold"
      [ngClass]="expanded ? 'font-bold' : 'font-normal'"
    >
      {{ accordion.title }}
    </div>
  </div>
  @if (this.expanded) {
    <markdown class="pl-6">{{ accordion.text }}</markdown>
  }
</div>
