<div class="flex flex-col">
  <div class="flex flex-row gap-4">
    @for (tab of informationTabs.tabs; track $index) {
      <div
        class="flex flex-row items-center gap-2 hover:underline cursor-pointer"
        (click)="selectTab(tab)"
      >
        @if (tab == selectedTab) {
          <img
            class="h-4"
            appImageUrl
            [iconData]="informationTabs.closeIcon.data"
          />
        } @else {
          <img
            class="h-4"
            appImageUrl
            [iconData]="informationTabs.openIcon.data"
          />
        }
        <div>{{ tab.title }}</div>
      </div>
    }
  </div>
  <div class="w-[900px] max-h-24 overflow-auto">
    @if (selectedTab) {
      <markdown>{{ selectedTab.text }}</markdown>
    }
  </div>
</div>
