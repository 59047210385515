import { animate, style, transition, trigger } from "@angular/animations";
import { OverlayRef } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import {
  Component,
  forwardRef,
  Inject,
  InjectionToken
} from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { MarkdownModule } from "ngx-markdown";
import { map, Observable } from "rxjs";
import { AppConfigService } from "../../../config.service";
import { ButtonComponent } from "../../../core/button/button.component";
import { Basket } from "../../../models/components/basket/basket.model";
import { BasketService } from "../../../services/strapi/basket.service";
import { PromoCodeInputComponent } from "../../elements/promo-code-input/promo-code-input.component";
import { ProductType } from "../../video-generator-form/models/video-form-config";
import { BasketItem } from "../models/basketItem.model";
import { BasketItemsService } from "../services/basketItems.service";


export const BASKET_MODAL_REF = new InjectionToken<OverlayRef>(
  "BASKET_MODAL_REF"
);

@Component({
  selector: 'app-basket-popup',
  standalone: true,
  templateUrl: "./basket-popup.component.html",
  styleUrls: ["./basket-popup.component.scss"],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MarkdownModule,
    forwardRef(() => ButtonComponent),
    PromoCodeInputComponent,
  ],
  animations: [
    trigger("openCloseBasket", [

      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("250ms ease-in-out", style({ transform: "translateX(0)" })),
      ]),
      transition(":leave", [
        style({ transform: "translateX(0%)" }),
        animate("250ms ease-in-out", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class BasketPopupComponent {
  basket$: Observable<Basket> | undefined;
  basketTotal: number;
  iconX = faX;
  iconTrash = faTrashCan;
  productType = ProductType;
  basketItems: BasketItem[] = [];

  strapiUrl: string;

  constructor(
    @Inject(BASKET_MODAL_REF) public overlayRef: OverlayRef,
    private readonly basketService: BasketService,
    private basketItemsService: BasketItemsService,
    private readonly configService: AppConfigService
  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  ngOnInit() {
    this.basket$ = this.basketService.getBasket().pipe(
      map((response) => {
        return response.data.basket.data.attributes;
      })
    );

    this.initItems();
  }

  initItems() {
    this.basketItems = this.basketItemsService.items;
    this.calculateBasketTotal();
  }

  calculateBasketTotal() {
    this.basketTotal = this.basketItems.reduce(
      (sum, project) => sum + project.price,
      0
    );
  }

  closePopup() {
    this.overlayRef.dispose();
  }

  removeItem(item: any) {
    this.basketItemsService.remove(item);
    this.initItems();
  }

  isBasketEmpty() {
    return this.basketItemsService.isBasketEmpty();
  }
}
