import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { AppConfigService } from "../../config.service";
import { ComponentEntry } from "../../models/strapi/componentEnty.model";
import { createImageUrl } from "../utils/create-image-url";

@Directive({
    selector: '[appImageUrl]',
    standalone: true,
})
export class ImageUrlDirective implements OnInit {
    @Input() iconData: ComponentEntry<any>;
    private strapiUrl: string;

    constructor(private el: ElementRef, private renderer: Renderer2, private readonly configService: AppConfigService) {
        this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
    }

    ngOnInit() {
        const url = createImageUrl(this.iconData, this.strapiUrl);
        this.renderer.setAttribute(this.el.nativeElement, 'src', url);
    }
}