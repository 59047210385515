import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { MarkdownModule } from 'ngx-markdown';
import { ButtonComponent } from '../../../core/button/button.component';
import { VideoCards } from '../../../models/components/sections/videoCards.model';
import { VideoCard } from '../../../models/components/videoCard.model';
import { ModalService } from '../../../services/modal.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { VideoCardComponent } from '../../elements/video-card/video-card.component';
import {
  INSTAGRAM_VIDEO_MODAL_REF,
  InstagramVideoModalComponent,
} from '../../modals/instagram-video-modal/instagram-video-modal.component';

@Component({
  standalone: true,
  selector: 'app-video-cards',
  templateUrl: './video-cards.component.html',
  styleUrls: ['./video-cards.component.css'],
  imports: [
    MarkdownModule,
    ButtonComponent,
    VideoCardComponent,
    FontAwesomeModule,
  ],
})
export class VideoCardsComponent implements OnInit {
  @Input() videoCards: VideoCards;
  readonly chevronLeftIcon = faChevronLeft;
  readonly chevronRightIcon = faChevronRight;
  readonly videoCardsOnViewCount = 5;
  cardsView = signal<VideoCard[]>([]);
  offset = signal(0);

  responsiveService = inject(ResponsiveService);
  modalService = inject(ModalService);

  ngOnInit(): void {
    this.setCardsView();
  }

  nextCard() {
    this.offset.update((currentOffset) => currentOffset + 1);
    this.setCardsView();
  }
  previousCard() {
    this.offset.update((currentOffset) => currentOffset - 1);
    this.setCardsView();
  }

  openVideoModal(currentVideoCard: VideoCard) {
    const ref = this.modalService.openModal<InstagramVideoModalComponent>(
      InstagramVideoModalComponent,
      INSTAGRAM_VIDEO_MODAL_REF,
    );

    ref.setInput('videoCards', this.videoCards.cards);
    ref.setInput('currentVideoCard', currentVideoCard);
  }

  private setCardsView() {
    if (this.offset() === this.videoCards?.cards.length) {
      this.offset.set(0);
    }
    if (this.offset() < 0) {
      this.offset.update(
        (currentOffset) => this.videoCards?.cards.length + currentOffset,
      );
    }

    let result = this.videoCards?.cards.slice(
      this.offset(),
      this.offset() + this.videoCardsOnViewCount,
    );
    if (result.length < 5) {
      result.push(
        ...this.videoCards?.cards.slice(
          0,
          this.videoCardsOnViewCount - result.length,
        ),
      );
    }
    this.cardsView.set(result);
  }
}
