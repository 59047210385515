<div class="flex flex-col items-start gap-2">
  <div class="text-4xl font-rockwell font-bold cursor-pointer" (click)="toggle()">
    {{ accordion.title }}
  </div>
  @if (this.expanded) {
    <markdown>{{ accordion.text }}</markdown>
    <div class="flex flex-col gap-2">
      @for (question of accordion.questions; track $index) {
        <app-accordion-secondary
          [accordion]="question"
        ></app-accordion-secondary>
      }
    </div>
  }
</div>
