import { ButtonsSectionData } from '../componentsData/buttonsSection.componentData';

export const FreeTextSection = `
          ... on ComponentSectionsFreeText {
            text
              buttonsSection {
    ${ButtonsSectionData}
  }
          }
`;
