import { Component, Input } from "@angular/core";
import { MarkdownModule } from "ngx-markdown";
import { AppConfigService } from "../../../config.service";
import { ButtonComponent } from "../../../core/button/button.component";
import { ReferenceCards } from "../../../models/components/sections/referenceCards.model";
import { ImageUrlDirective } from "../../../shared/directives/image-url.directive";

@Component({
  standalone: true,
  selector: 'app-reference-cards',
  templateUrl: './reference-cards.component.html',
  styleUrls: ['./reference-cards.component.css'],
  imports: [ButtonComponent, MarkdownModule, ImageUrlDirective],
})
export class ReferenceCardsComponent {
  @Input() referenceCards: ReferenceCards;
  strapiUrl: string;

  constructor(private readonly configService: AppConfigService) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  scrollToElement(elementId: string) {
    if (!elementId) {
      return;
    }

    document.getElementById(elementId)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
