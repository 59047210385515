<div class="absolute inset-0 z-50">
  <div
    class="fixed bg-black bg-opacity-75 backdrop-blur-lg h-full w-full flex place-items-center place-content-center"
  >
    <iframe
      [src]="videoUrl | safe"
      class="aspect-video"
      [ngClass]="!isNarrowerView() ? 'w-[60vw]' : 'w-full'"
      allow="fullscreen;"
    ></iframe>
    <div class="buttons">
      <button class="change-button" (click)="closePopup()">
        <fa-icon
          [icon]="timesIcon"
          class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </button>
    </div>
  </div>
</div>
