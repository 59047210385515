<div class="flex flex-row gap-2">
  <div
    class="relative flex w-full items-center overflow-hidden rounded-lg p-0.5"
  >
    @if (!discountCode()) {
      <div
        class="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#ff0000_20deg,transparent_120deg)]"
      ></div>
    }
    <div class="relative w-full rounded-md bg-white">
      <div class="form-field">
        <input
          type="text"
          [formControl]="control"
          [ngClass]="{ filled: control.value, applied: discountCode() }"
        />
        <label class="pointer-events-none">Discount Code</label>
        <fa-icon class="valid-mark" size="sm" [icon]="faCheckCircle" />
      </div>
    </div>
  </div>
  <button
    class="border px-4 rounded-lg bg-gray-100 apply-button my-0.5"
    [disabled]="!control.value"
    [ngClass]="{ filled: control.value }"
    (click)="handleButtonClick()"
  >
    @if (discountCode()) {
      <div class="flex gap-1">
        <fa-icon [icon]="faTrash" [size]="'sm'" />
        <span>Remove</span>
      </div>
    } @else {
      <span>Apply</span>
    }
  </button>
</div>
